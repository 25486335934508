import React, { useContext, useState } from 'react'
import Chat from '../chat/Chat.component'
import EquityandDarkCardComponent from '../equity&darkCard/EquityandDarkCard.component'
import AiCard from '../aicard/AiCard.component'
import WallstflowAppCard from '../wallstflowAppcard/WallstflowAppCard.component'
import OOF from '../oofCard/OOF.component'
import { appCardData } from '../../dataArray'
import { firestore } from '../../../firebase/firebase.utils'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { GridLoader } from 'react-spinners'
import { AuthContext } from '../../../context/context'
import { useNavigate } from 'react-router'

interface props {
  search: string
}

const Home: React.FC<props> = ({ search }) => {
  const { user, plans } = useContext(AuthContext)
  const navigate = useNavigate()
  const query = firestore.collection('widgets').doc('flow')

  if (!user || !plans) {
    navigate('/')
  }
  console.log(plans)
  const [value, loading, error] = useDocumentData(query, {
    idField: 'id',
  })

  return (
    <div className='app-main-leftandright-container'>
      <div className='app-main-left-container'>
        <div
          className={`app-main-cards-container ${
            loading ? 'loading_container' : ''
          }`}
        >
          {!loading ? (
            value?.value.map((el: any, index: number) => (
              <div key={index} style={{ flex: '1' }}>
                <WallstflowAppCard
                  steps={appCardData[index].steps}
                  heading={appCardData[index].heading}
                  subHeading={el.value}
                  progress={
                    index !== 1
                      ? parseFloat(el.perc.split('%')[0])
                      : parseFloat(el.perc) * 100
                  }
                  strokeColor={appCardData[index].strokeColor}
                />
              </div>
            ))
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <GridLoader color={'#D3EAFF'} loading={loading} size={25} />
            </div>
          )}
        </div>
        <OOF search={search} textHeading='OPTIONS ORDER FLOW' />
      </div>
      <div className='app-main-right-container'>
        <div className='ed-container-home'>
          <EquityandDarkCardComponent search={search} />
        </div>
        <div className='ai-container-home'>
          <AiCard />
        </div>
      </div>
    </div>
  )
}

export default Home
