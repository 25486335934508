import React, { useState } from "react";
import HistoryOOF from "../HistoryoofCard/HistoryOOF.component"
import "./history.style.scss";
import Button from "../../button/Button";
import Sidebar from "../sidebar/Sidebar.component";
import AppNav from "../appNavbar/appNav.component";

const History:React.FC = () => {
  const [bitchValues, setBitchValues] = useState<any>({
    search: 'AAPL',
    date: "2021-10-24",
    date2: "2022-01-24"
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value
    setBitchValues({
      ...bitchValues,
      [name]: value
      
      
    })
  }

  return (
    <>
        <div className="history-container">
        <Sidebar/>
        <div className="history-right-container"><AppNav />
          <div className="history-form-container">
    

        <form className="history-form" onSubmit={(e) => e.preventDefault()}>
          <div className="history-search">
          <input
            style={{backgroundColor:"#3D3C76",fontWeight:"bold", border:"none", borderRadius:"0.6em", color:"#66FFDA",fontFamily:"poppins", width:"100%", padding:"0.5em"}}
            id='search'
            name='search'
            type='text'
            value={bitchValues.search}
            onChange={e => handleChange(e)}
          />
          </div>
          <div className="history-date-start">
          <input
          style={{backgroundColor:"#3D3C76", border:"none", borderRadius:"0.6em", color:"#66FFDA",fontFamily:"poppins", width:"100%", padding:"0.5em"}}
            id='date'
            name='date'
            type='date'
            value={bitchValues.date}
            onChange={e => {handleChange(e)
            console.log(e)}}
          />
          </div>
          <div className="history-date-end">
          <input
          style={{backgroundColor:"#3D3C76", border:"none", borderRadius:"0.6em", color:"#66FFDA",fontFamily:"poppins", width:"100%", padding:"0.5em"}}
            id='date2'
            name='date2'
            type='date'
            value={bitchValues.date2}
            onChange={e => {handleChange(e)
              console.log(e)}}
          />
          </div>
        </form>
      </div>
      <HistoryOOF value={bitchValues}  textHeading="HISTORICAL DATA" />
    </div>
    </div>
    </>
  );
};

export default History;
