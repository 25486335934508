import React, { useRef, useState, useEffect } from "react";
// import Classes from './signin.module.scss';
import { useMedia } from "use-media";
import SignIn from "../login/signin/signin.component"
import {Formik} from 'formik';
import "./LoginFirst.scss";
import "./Login.scss";
import { useNavigate } from "react-router-dom";

import { auth } from "../../../firebase/firebase.utils";
import Button from "../../button/button.component"
import Input from "../../input/input.component";
import * as Yup from 'yup'
import { FiArrowRightCircle } from "react-icons/fi";
import {gsap, Power3} from 'gsap'

import { ClipLoader } from "react-spinners";


const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
})
interface props {
  popUpToggle:string
  setPopUp: any
  first: boolean
  setFirst: any
}
const Login:React.FC<props>=({ popUpToggle, setPopUp, first, setFirst })=> {
  
  const loginContainerRef = useRef(null)
  const anyRef = useRef(null)
  useEffect(() => {

  },[]);
  const [wrong, setWrong] = useState<string | boolean>('')
  const [toggler, setToggler] = useState<boolean>(false)
  const navigate = useNavigate()
  
  const signin = async (email: string, pass: string) => {
  
    try {
      const user = await auth.signInWithEmailAndPassword(email, pass)
      if (user) {
        navigate('main')
       
      }
    } catch (error : any) {
      setWrong(error.code)
    }
    setPopUp('')
  }
  // ref={(first===true)?loginContainerRef:anyRef}
    return (
      <div style={{transform:"scale(0.8)"}}>
      <div className={`login-container-first ${first===true?"first":"notfirst"}`} >
        <div className="login">
          <div className="login-top">
            <div className="login-title">
              <abbr className="login-title" title="this is the login">
                Log in
              </abbr>
            </div>
          </div>
          <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  signin(values.email, values.password)
                  setSubmitting(false)
                }, 100)
              }}
              validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props

                return (
                  <div className="formik-login-container" >
                    
                  <form
                  className="signin_form"
                  onSubmit={handleSubmit}
                  noValidate={true}
                >
                  <Input
                    id='email'
                    name='email'
                    type='text'
                    value={values.email}
                    onChange={(e) => {
                      handleChange(e)
                      setWrong(false)
                    }}
                    onBlur={handleBlur}
                    errors={
                      wrong === 'auth/user-not-found'
                        ? 'User Does not exist'
                        : errors.email
                    }
                    touched={touched.email}
                    blank='form'
                    extra='signin-input'
                  />
                  <Input
                    id='password'
                    name='password'
                    type='password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.password}
                    touched={touched.password}
                    blank='form'
                    extra='signin-input'
                  />

            <div className="login-btn-container">
        
            <Button types='submit' disabled={isSubmitting}>
                        {
                          !isSubmitting
                          ?
                        'Sign In'
                        :
                        <ClipLoader color={'#26667a'} loading={isSubmitting} size={20} />
                        }
                      </Button>
            <div
              className="or-use-phone"
             
              onClick={() => {
                setPopUp("loginphone");
               
              }}
            >
              or use <span  style={{fontWeight:"bold", marginLeft:"0.4em"}}>Phone no.</span>
            </div>
          </div>
                </form>
                    </div>
                    )
              }  
             }

            </Formik>
          
          <div className="forgot-password">Forgot Password?</div>
          <div className="extras">
            <div className="forgot-password-text">
              {" "}
              don't have an account yet?{" "}
              <span
                className="register-here"
                onClick={() => {
                  setPopUp("register");
                  setFirst(false);
                }}
              >
                REGISTER HERE
              </span>
            </div>
            <div className="if-purchased">
              {" "}
              <div
                className="login-bottom-bar"
                style={{
                  margin: "2em auto",
                  width: "100%",
                  height: "0.4em",
                  borderRadius: "10px",
                  backgroundColor: "white",
                }}
              ></div>
              <div
                className="bundle-package"
                onClick={() => {
                  setPopUp("loginsecret");
                }}
              >
                {" "}
                OR Bundle Users{" "}
                <div className="bundle-package-arrow">
                  <FiArrowRightCircle />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  } 

export default Login;
