import React from 'react'
import Footer from '../footer/Footer'
import Navbar from '../landingPage Navbar/Navbar'
import MainContent from '../mainContent/MainContent'
import logo from "../../../assets/logo.png";
import HeroSection from "../mainContent/herosection/HeroSection";
import  './landingMain.style.scss'
import Checkout from '../../checkout/checkout.component';

const LandingMain = () => {
    return (
      <>
        <div className='landing-main-container'>
      <Navbar logo={logo} />
      
      <HeroSection />
      <MainContent />
      
        </div>
        <Footer />
        </>
    )
}

export default LandingMain
