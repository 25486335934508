import React from 'react'
import { auth, firestore } from '../firebase/firebase.utils'
import firebase from 'firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { GridLoader } from "react-spinners";
import "./grid.scss"

interface IContextProps {
  user: firebase.User | null | undefined
  plans: Array<any>
}

export const AuthContext = React.createContext({} as IContextProps)

const AuthProvider = ({ children }: any | null) => {
  const [user, loadingauth] = useAuthState(auth)
  let ref = null
  if (user) {
    ref = firestore.collection('users').doc(user?.uid)
  }
  const [value, loading] = useDocumentData<any>(ref)

  if (loadingauth) {
    
    return   <div className='loading_container'><div style={{display:"flex", flexDirection:"column"}}>
            <GridLoader color={'#D3EAFF'} loading={loading} size={50} />
            </div>
            </div>
  } else if (loading) {
    return   <div className='loading_container'>
          <div style={{display:"flex", flexDirection:"column"}}>
            <GridLoader color={'#D3EAFF'} loading={loading} size={50} />
           </div>
           </div> 
  } else {
    if (!value && user) {
      return    <div className='loading_container'>
              <div style={{display:"flex", flexDirection:"column"}}>
                <GridLoader color={'#D3EAFF'} loading={loading} size={50} />
              </div>
              </div> 
    }
    return (
      <AuthContext.Provider
        value={{
          user: user,
          plans: value && value.activePlans ? value.activePlans : null,
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
}

export default AuthProvider
