import React, { useContext, useState } from 'react'
import SignIn from '../login/signin/signin.component'
import './giftpopup.style.scss'
import { FaInfoCircle } from 'react-icons/fa'
import Button from '../../button/button.component'
import { fetchFromAPI } from '../../../firebase/helper'
import { AuthContext } from '../../../context/context'
import { useNavigate } from 'react-router'
const Giftpopup = () => {
  const { user } = useContext(AuthContext)
  const [msg, setMsg] = useState<string | null>(null)
  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {
      const res = await fetchFromAPI('giftEntry', {})
      setMsg(res.message)
      if (res.message === 'user added') {
        setTimeout(() => {
          navigate('/main')
        }, 1500)
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className='gift-pop-up-container'>
      <h1 className='gift-header'>CLAIM YOUR FREE SUBSCRIPTION</h1>
      {user ? (
        <h3 style={{ marginTop: '20px' }}>Signed In Already!</h3>
      ) : (
        <SignIn />
      )}
      {user && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button click={handleSubmit} types='submit'>
            Activate
          </Button>
        </div>
      )}
      {msg && <h3 style={{ marginTop: '10px' }}>{msg}</h3>}
      <div
        style={{
          display: 'flex',
          gap: '10px',
          width: '110%',
          marginTop: '2em',
        }}
      >
        <FaInfoCircle className='info-icon' />
        <div
          style={{
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <p className='gift-feature'>
            New user? Get 14 days free subscription!
          </p>
          <p className='gift-feature'>
            Premium user? Get 2 months free subscription!
          </p>
        </div>
      </div>
    </div>
  )
}

export default Giftpopup
