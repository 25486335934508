import React from "react";
import "./Carousel.scss";
interface props{
  name:string
  testimonial:string
  image:any
}
const TestimonialsCard:React.FC<props>=({ name, testimonial, image })=>{
  return (
    <div>
      <div className="carousel-container">
        <div className="carousel">
          <div className="client-card">
            <div className="client-heading-container">
              <div className="client-image">
                <img className="client-image" src={image} alt="client"></img>
              </div>
              <div className="client-name-container">
                <div className="client-name">{name}</div>
                <div className="client-profession">client</div>
              </div>
            </div>
            <div className="client-testimonial-container">
              <div className="client-testimonial">{testimonial}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialsCard;
