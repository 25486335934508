import React, { useEffect, useState, useContext } from 'react'
import firebase, { firestore } from '../../../../firebase/firebase.utils'
import { AuthContext } from '../../../../context/context'
import './watchlistTile.style.scss'

interface props {
  ticker: string
  action: boolean
}
const WatchlistTile: React.FC<props> = ({ ticker, action }) => {
  const [data, setData] = useState<any>()
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const ticketFetch = async () => {
      const snap = await firestore
        .collection('sweeps')
        .orderBy('createdAt', 'desc')
        .where('ticker', '==', ticker)
        .limit(1)
        .get()
      if (snap.empty) {
        setData(null)
        return
      }
      setData(snap.docs[0].data())
    }
    ticketFetch()
  }, [ticker])

  const actionExecuter = async (action: boolean) => {
    await firestore
      .collection('watchlist')
      .doc(user?.uid)
      .update({
        ticker: action
          ? firebase.firestore.FieldValue.arrayRemove(ticker)
          : firebase.firestore.FieldValue.arrayUnion(ticker),
      })
  }

  return (
    <div className='watchlist-tile-container'>
      {data ? (
        <>
          <div className='flex1 '>
            <div className='ticker'>{ticker}</div>
          </div>
          <div className='flex1'>{data.price}</div>
          <div className='flex1'>{data.strike}</div>
          <div className='flex1'>{data.premium}</div>
          <div className='flex1 watchlistDel'>
            {/* <div className="watchlist-minus">-</div> */}
            <div
              onClick={() => actionExecuter(action)}
              className={`${action ? 'watchlist-minus' : 'watchlist-plus'}`}
            >
              {action ? '-' : '+'}
            </div>
          </div>
        </>
      ) : (
        <h2>Loading...</h2>
      )}
    </div>
  )
}

export default WatchlistTile
