import React from 'react'
import Classes from './button.module.scss'

interface props {
  types: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  children: React.ReactNode
  quality?: string | boolean | undefined
  click?: () => void | undefined | Promise<void>
  price?: string | undefined
  clicked?: (_: string | undefined) => void | undefined
}

const Button: React.FC<props> = ({
  types,
  disabled,
  children,
  quality,
  click,
  price,
  clicked,
}) => {
  return (
    <button
      type={types}
      disabled={disabled}
      className={`${
        quality === '1'
          ? Classes.homebutton
          : quality === '2'
          ? Classes.outlinebutton
          : quality === true
          ? Classes.prem
          : quality === "red"
          ? Classes.redbtn
          : quality === "white"
          ? Classes.whitebtn
          : quality === "blue"
          ? Classes.bluebtn
          :quality === "gold"
          ? Classes.goldbtn
          :''
      } ${Classes.custom_button}`}
      onClick={
        clicked
          ? () => {
              clicked(price)
            }
          : click
      }
    >
      {children}
    </button>
  )
}

export default Button
