import React from "react";
import Login from "../login/Login";
import Register from "../register/Register";
import Loginphone from "../login/loginphone";
import Loginsecret from "../login/Loginsecret";
import "./DisplayLoginRegister.scss";
import Giftpopup from "../giftpopup/giftpopup";

interface props{
  popUpToggle:string
  setPopUp:any
  first:boolean
  setFirst:any
}
const DisplayLoginRegister:React.FC<props>=({
  popUpToggle,
  setPopUp,
  first,
  setFirst,
})=> {
  return popUpToggle === "" ? null : popUpToggle === "login" ? (
    <div className="active">
      <Login
        popUpToggle={popUpToggle}
        setPopUp={setPopUp}
        first={first}
        setFirst={setFirst}
      />
      <div
        className="display-login-register-bg"
        onClick={() => {
          setPopUp(null);
        }}
      ></div>
    </div>
  ) : popUpToggle === "register" ? (
    <div className="active">
      <Register
        popUpToggle={popUpToggle}
        setPopUp={setPopUp}
        first={first}
        setFirst={setFirst}
      />
      <div
        className="display-login-register-bg"
        onClick={() => {
          setPopUp(null);
        }}
      ></div>
    </div>
  ) : popUpToggle === "loginsecret" ? (
    <div className="active">
      <Loginsecret
        popUpToggle={popUpToggle}
        setPopUp={setPopUp}
        first={first}
        setFirst={setFirst}
      />
      <div
        className="display-login-register-bg"
        onClick={() => {
          setPopUp(null);
        }}
      ></div>
    </div>
  ) : popUpToggle === "loginphone" ? (
    <div className="active">
      <Loginphone
        popUpToggle={popUpToggle}
        setPopUp={setPopUp}
        first={first}
        setFirst={setFirst}
      />
      <div
        className="display-login-register-bg"
        onClick={() => {
          setPopUp(null);
        }}
      ></div>
    </div>
  ) : popUpToggle === "gift"? (
    <div className="active">
    <Giftpopup/>

      <div
        className="display-login-register-bg"
        onClick={() => {
          setPopUp(null);
        }}
      ></div>
    </div>
  ):null;

}
export default DisplayLoginRegister;

