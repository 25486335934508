import React from 'react'
import './twitchCapsule.style.scss'
import { AiOutlineTwitter } from 'react-icons/ai'

interface props {
  data: any
}
const TwitchCapsule: React.FC<props> = ({ data }) => {
  return (
    <div className='twitch-capsule-container'>
      <p className='user-tweet'>{data.data.text}</p>
      <p className='user-handle'>
        <AiOutlineTwitter />
        {data.includes.users[0].username}
      </p>
    </div>
  )
}

export default TwitchCapsule
