import React, {  useState, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { AuthContext } from '../../../context/context'
import Classes from './register.module.scss'

import Button from "../../button/button.component";
// import "./Register.scss";
// import "./RegisterFirst.scss";
import Input from "../../input/input.component"
// import { HiOutlineMail } from "react-icons/hi";
// import { MdOutlinePermIdentity } from "react-icons/md";
// import { RiLockPasswordLine } from "react-icons/ri";
// import { BsTelephone } from "react-icons/bs";
import { Formik, Field } from 'formik';
import { auth, createUserProfileDocument } from '../../../firebase/firebase.utils'
import * as Yup from 'yup'

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    username: Yup.string().min(3, 'Username is too short').required('Required'),
    email: Yup.string().email().required('Required'),
    password: Yup.string()
      .required('No password provided')
      .min(8, 'Too short - should be 8 chars minimum')
      .matches(/(?=.*[0-9])/, 'Password must contain a number'),
    confirmPassword: Yup.string()
      .required('No password provided')
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .min(8, 'Too short - should be 8 chars minimum')
      .matches(/(?=.*[0-9])/, 'Password must contain a number'),
    acceptTerms: Yup.bool().oneOf(
      [true],
      'Accepting Terms & Conditions is required'
    ),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  })
  
interface props {
  popUpToggle: string
  setPopUp: any
  first: boolean
  setFirst: any
}
  const Register: React.FC<props> = ({popUpToggle, setPopUp, first, setFirst}) => {
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)
    if (user) {
      navigate('main')
    }
  
    const [usedEmail, setUsedEmail] =
      useState<HTMLInputElement | string | boolean>(false)
  
    const signup = async (values: any) => {
      try {
        const { user } = await auth.createUserWithEmailAndPassword(
          values.email,
          values.password
        )
        user &&
          user
            .sendEmailVerification()
            .then(() => {
              console.log('email sent')
            })
            .catch((err:any) => {
              console.log(err)
            })
  
        await createUserProfileDocument(user, values)
        navigate('')
      } catch (error:any) {
        console.log(error)
        if (
          error.message ===
          'The email address is already in use by another account.'
        ) {
          setUsedEmail(error.message)
        }
      }
    }
  
  if (first === true) {
    return (
      // <div className={`${Classes.input_container} register-container-first`}>
      <div style={{transform:"scale(0.8)"}}>
      <div className={`${Classes.register} ${Classes.first}`}>
        <div className={Classes.input_container }>
      <h1>Register</h1>
      <Formik
        initialValues={{
          acceptTerms: false,
          firstName: '',
          lastName: '',
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
          phone: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            signup(values)

            console.log('Logging in', values)
            setSubmitting(false)
          }, 100)
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props

          return (
            <form
              className={Classes.register_form}
              onSubmit={handleSubmit}
              noValidate={true}
            >
              <span className={Classes.club}>
                <Input
                  id='firstName'
                  name='firstName'
                  type='text'
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.firstName}
                  touched={touched.firstName}
                  extra='register-input'
                  blank='form'
                />

                <Input
                  id='lastName'
                  name='lastName'
                  type='text'
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.lastName}
                  touched={touched.lastName}
                  extra='register-input'
                  blank='form'
                />
              </span>
              <span className={Classes.club}>
                <Input
                  id='username'
                  name='username'
                  type='text'
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.username}
                  touched={touched.username}
                  extra='register-input'
                  blank='form'
                />
                <Input
                  id='email'
                  name='email'
                  type='text'
                  value={values.email}
                  onChange={(event) => {
                    handleChange(event)
                    setUsedEmail(event.target)
                  }}
                  onBlur={handleBlur}
                  errors={
                    usedEmail ===
                    'The email address is already in use by another account.'
                      ? 'Email already in use'
                      : errors.email
                  }
                  touched={touched.email}
                  extra='wide'
                  blank='form'
                />
              </span>
              <span className={Classes.club}>
                <Input
                  id='password'
                  name='password'
                  type='password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.password}
                  touched={touched.password}
                  extra='register-input'
                  blank='form'
                />
                <Input
                  id='confirmPassword'
                  name='confirmPassword'
                  type='password'
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  extra='register-input'
                  blank='form'
                />
              </span>
              <Input
                id='phone'
                name='phone'
                type='tel'
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.phone}
                touched={touched.phone}
                extra='register-input'
                blank='form'
              />

              <div className={Classes.form_group + ' ' + Classes.form_check}>
                <Field
                  type='checkbox'
                  name='acceptTerms'
                  className={
                    Classes.form_check_input +
                    (errors.acceptTerms && touched.acceptTerms
                      ? ' ' + Classes.is_invalid
                      : '')
                  }
                />
                <label
                  htmlFor='acceptTerms'
                  className={Classes.form_check_label}
                >
                  Accept Terms & Conditions,
                  <Link to='/privacy-policy' target='_blank'>
                    {' '}
                    click here to read.
                  </Link>{' '}
                </label>
                {errors.acceptTerms && touched.acceptTerms && (
                  <div className={Classes.input_feedback}>
                    {errors.acceptTerms}
                  </div>
                )}
              </div>

              <Button types='submit' disabled={isSubmitting}>
                Register
              </Button>
              <div onClick={()=>{setPopUp("login")
    setFirst(false)}} style={{ cursor:"pointer", margin:"1em auto"}}>
      Already have an account?{` `}
      <span style={{fontWeight: "bold"}}> SIGN IN HERE</span>
    </div>
            </form>
          )
        }}
         
      </Formik>

    
  
    </div>
  </div>
  </div>
    );
  } else if (first === false) {
    return (
      // <div className={`${Classes.input_container} register-container`} >
      <div style={{transform:"scale(0.8)"}}>
      <div className={`${Classes.register} ${Classes.notfirst}`}>
      <div className={Classes.input_container }>
    <h1>Register</h1>
    <Formik
      initialValues={{
        acceptTerms: false,
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          signup(values)

          console.log('Logging in', values)
          setSubmitting(false)
        }, 100)
      }}
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props

        return (
          <form
            className={Classes.register_form}
            onSubmit={handleSubmit}
            noValidate={true}
          >
            <span className={Classes.club}>
              <Input
                id='firstName'
                name='firstName'
                type='text'
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.firstName}
                touched={touched.firstName}
                extra='register-input'
                blank='form'
              />

              <Input
                id='lastName'
                name='lastName'
                type='text'
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.lastName}
                touched={touched.lastName}
                extra='register-input'
                blank='form'
              />
            </span>
            <span className={Classes.club}>
              <Input
                id='username'
                name='username'
                type='text'
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.username}
                touched={touched.username}
                extra='register-input'
                blank='form'
              />
              <Input
                id='email'
                name='email'
                type='text'
                value={values.email}
                onChange={(event) => {
                  handleChange(event)
                  setUsedEmail(event.target)
                }}
                onBlur={handleBlur}
                errors={
                  usedEmail ===
                  'The email address is already in use by another account.'
                    ? 'Email already in use'
                    : errors.email
                }
                touched={touched.email}
                extra='wide'
                blank='form'
              />
            </span>
            <span className={Classes.club}>
              <Input
                id='password'
                name='password'
                type='password'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.password}
                touched={touched.password}
                extra='register-input'
                blank='form'
              />
              <Input
                id='confirmPassword'
                name='confirmPassword'
                type='password'
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.confirmPassword}
                touched={touched.confirmPassword}
                extra='register-input'
                blank='form'
              />
            </span>
            <Input
              id='phone'
              name='phone'
              type='tel'
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.phone}
              touched={touched.phone}
              extra='register-input'
              blank='form'
            />

            <div className={Classes.form_group + ' ' + Classes.form_check}>
              <Field
                type='checkbox'
                name='acceptTerms'
                className={
                  Classes.form_check_input +
                  (errors.acceptTerms && touched.acceptTerms
                    ? ' ' + Classes.is_invalid
                    : '')
                }
              />
              <label
                htmlFor='acceptTerms'
                className={Classes.form_check_label}
              >
                Accept Terms & Conditions,
                <Link to='/privacy-policy' target='_blank'>
                  {' '}
                  click here to read.
                </Link>{' '}
              </label>
              {errors.acceptTerms && touched.acceptTerms && (
                <div className={Classes.input_feedback}>
                  {errors.acceptTerms}
                </div>
              )}
            </div>

            <Button types='submit' disabled={isSubmitting}>
              Register
            </Button>
          </form>
        )
      }}
    </Formik>

   <div onClick={()=>{setPopUp("login")
    setFirst(false)}} style={{marginTop:"2em", cursor:"pointer"}}>
      Already have an account?{` `}
      <span style={{fontWeight: "bold"}}> SIGN IN HERE</span>
    </div>
  </div>
</div>
</div>
  );
  }
  else return null
}

export default Register;
