import React,{useEffect, useRef, useState} from "react";
import "./Historyoof.style.scss";
import OOFtile from "../ooftile/OOFtile.component";
import firebase, { firestore } from "../../../firebase/firebase.utils";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {oofTable} from "../../dataArray";
import { GridLoader } from "react-spinners";



interface props{
  textHeading:string
  value?: any
  search?: string
 
}
const HistoryOOF:React.FC<props> = ({ textHeading, value, search }) => {
  const [mobileLink, setLink] = useState('oof')

  const sweepRef = firestore.collection('sweeps')
  let query = null
    query = sweepRef.orderBy('createdAt', 'desc').limit(300) //limit

    

    
    if (value) {
    const timeBefore = firebase.firestore.Timestamp.fromDate(new Date(value.date))
    const timeAfter = firebase.firestore.Timestamp.fromDate(new Date (value.date2))
    
    query = sweepRef
            .where('createdAt', '>=', timeBefore)
            .where('createdAt', '<=', timeAfter)
            .where('ticker', '==', value.search).limit(100)
            
          }
    if (search) {
      query = sweepRef.where('ticker', '==', search).limit(300)
    }        

  const [sweeps, loading] = useCollectionData<any>(query, {
    idField: 'id',
  })
 
  return (
    <div className="history-options-order-flow-container">
      <div className="history-oof-header">
    
        <h1 className="history-oof-textheading">{textHeading}</h1>
        
        <div className="history-oof-table">
          {" "}

         {
            oofTable.map((el:any, index)=>(
              <div className="flex1" key={index}>{el}</div>
            ))

          }
        
        </div>
        
        <div className={`history-oof-main-tiles ${loading ? 'loading_container':''} `}>
          
          {!loading&&(mobileLink==='oof')
          ?
          sweeps && sweeps.map((data:any)=>
          <div key={data.id} className="history-oof-tile-background">
            <OOFtile lastTrade={data.lastTrade} price={data.price} type={data.type} ticker={data.ticker} strike={data.strike}
             expDate={data.expDate} DTE={data.DTE} details={data.details} openInt={data.openInt}
             IV={data.IV}
             premium={data.premium}/>
            </div>
          )
        :
       <div style={{display:"flex", flexDirection:"column"}}>
        <GridLoader color={'#D3EAFF'} loading={loading} size={25} />
        </div>
        }

  
        </div>
      </div>
    </div>
  );
};

export default HistoryOOF;
