// import { gsap, Power3 } from "gsap";
import Lottie from "lottie-web";
import React, { useEffect, useRef,} from "react";
import "./ScrollDownAnim.style.scss";
const ScrollDownAnim:React.FC = () => {
  let ScrollDownRef = useRef<HTMLDivElement>(null);



  useEffect(() => {
    if(ScrollDownRef.current)
    Lottie.loadAnimation(
      {
        container: ScrollDownRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: require("../../../lotties/scrolldownlottie.json"),
      },

    );
  }, []);

  return <div className="scrollDown-anim-container" ref={ScrollDownRef}></div>;
};

export default ScrollDownAnim;
