import React, { useEffect, useState } from "react";
import "./wallstflowAppCard.style.scss";
import ProgressBar from 'react-customizable-progressbar'

interface props{
  heading: string
  subHeading: string
  progress: number
  strokeColor: string
  steps: number
}
const WallstflowAppCard:React.FC<props>=({heading, subHeading, progress, strokeColor, steps})=> {
  
  // const [timer, setTimer] = useState<number>(0)
  //   const closer = setInterval(() => {
  //     setTimer(p => p + 1)
  //     console.log('increasing')
  //   }, 2000)
  //   if (timer >= 100) {
  //     clearInterval(closer)
  //   }
  return (
    <div className="app-card-container">
      <div className="app-card-heading">{heading}
      <div className={`app-card-subheading ${subHeading==="BULLISH"?"green": ''}`}>{subHeading}</div>
      </div>
   
      <ProgressBar
        progress={progress}
        steps={steps}
        radius={30}
        rotate={180}
        strokeWidth={10}
        strokeColor={strokeColor}
        trackStrokeWidth={15}
        trackStrokeColor={'rgba(172, 143, 143, 0)'}>
        <div className="progress-value">{`${steps===10?progress+'':progress+'%'}`}</div></ProgressBar>
          
      {/* <div className="app-card-content">
        <div>Bullish</div>
        <div></div>
      </div> */}
    </div>
  );
}

export default WallstflowAppCard;
