import React, { useRef, useState, useEffect } from "react";
import "./BenefitsCard.scss";
import SubCard from "../subCard/SubCard";
import { gsap, Power3 } from "gsap";
import { subCardArr } from "../../../../dataArray";
import { TiTick } from "react-icons/ti";
interface props{
  benefitsArr: any
}
const BenefitsCard:React.FC<props>=({ benefitsArr })=>
{
  const [isShown, setIsShown] = useState<boolean>(true);
  const subscriptionRef = useRef<HTMLDivElement>(null);
  const benefitsHoverRef = useRef<HTMLDivElement>(null);
  useEffect(()=>{

    // gsap.from(
    //   subscriptionRef.current,
    //   {
    //     opacity:0,
    //     y: 100,
    //     ease: Power3.easeOut,
    //     scrollTrigger: {
    //       trigger: ".benefits-container",
    //       start: "60%",
    //       end: "+=100px",
    //       scrub: 2,
    //       toggleActions: "restart complete none reverse",
    //       onLeaveBack: (self) => self.disable(),
    //     },
    //   },
    // );


  }, [])



  

  return (
    <div className="benefits-container">
      <div className="subscription-cards" ref={subscriptionRef}>
        {subCardArr.map((card) => (
          <SubCard
            key={card.planPrice}
            planType={card.planType}
            originalPrice={card.originalPrice}
            planPrice={card.planPrice}
            duration={card.duration}
            navigateLink={card.navigateLink}
            setIsShown={setIsShown}
          />
          
        ))}
      </div>
      {true &&( <div className="benefits-hover-container" ref={benefitsHoverRef} >
      <div className="benefits-heading">Benefits:</div>
            <div className="benefits-box">
              <div className="benefits-content">
                {benefitsArr.map((item:any) => (
                  <div key={item} className="benefits-item">
                    <div
                      className="tick"
                      style={{ color: "darkblue", fontSize: "1.2rem" }}
                    >
                      <TiTick />
                    </div>
                    {item}
                  </div>
                ))}
              </div>
            </div>
            </div>) }
     
    </div>
    
  );

  

          }
export default BenefitsCard;
