import React from "react";
import "./charts.style.scss";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import Sidebar from "../sidebar/Sidebar.component";
import AppNav from "../appNavbar/appNav.component";
const Charts:React.FC = () => {
  return (
    <div className="charts-container">
      <div className="charts-container-left">
        <Sidebar/>
      </div>
      <div className="charts-container-right">
        <AppNav/>
      
        <div className="charts">
          <AdvancedRealTimeChart theme="dark" autosize></AdvancedRealTimeChart>
          </div>
      </div>
    </div>
  );
};

export default Charts;
