import React, { useState, useLayoutEffect, useRef } from 'react'
import { useMedia } from 'use-media'
import AppNav from '../appNavbar/appNav.component'
import Sidebar from '../sidebar/Sidebar.component'
import './appnews.style.scss'
import { FiMinimize2 } from 'react-icons/fi'
import { FiMaximize2 } from 'react-icons/fi'
import { gsap, Elastic, Power3 } from 'gsap'
import TwitchCapsule from './twitchCapsule.component'
import { firestore } from '../../../firebase/firebase.utils'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { GridLoader } from 'react-spinners'
import { ReactTinyLink } from 'react-tiny-link'

const News: React.FC = () => {
  const isMobile = useMedia({ maxWidth: '810px' })
  const [mobileLink, setLink] = useState('hn')
  const [livenewstoggle, setlivenews] = useState<boolean>(false)
  const [sourcenewstoggle, setsourcenews] = useState<boolean>(false)
  const minimaxliveref = useRef<HTMLDivElement>(null)
  const minimaxsourceref = useRef<HTMLDivElement>(null)

  const query = firestore
    .collection('twitterNews')
    .orderBy('createdAt', 'desc')
    .limit(50)

  const [value, loading, error] = useCollectionData(query, {
    idField: 'id',
  })

  useLayoutEffect(() => {
    gsap.fromTo(
      minimaxliveref.current,
      {
        scaleY: '2',
        x: '-10px',
        y: '10px',
        rotate: '20deg',
        opacity: '0',
        delay: 1,
        ease: Power3.easeInOut,
      },
      {
        scaleY: '1',
        x: '0px',
        y: '-10px',
        rotate: '0deg',
        opacity: 1,
        ease: Elastic.easeInOut,
      }
    )
    gsap.fromTo(
      minimaxsourceref.current,
      {
        scaleY: '2',
        x: '-10px',
        y: '10px',
        rotate: '20deg',
        opacity: '0',
        delay: 1,
        ease: Power3.easeInOut,
      },
      {
        scaleY: '1',
        x: '0px',
        y: '-10px',
        rotate: '0deg',
        opacity: 1,
        ease: Elastic.easeInOut,
      }
    )
  })

  const handleLive = () => {
    if (sourcenewstoggle === true) {
      setsourcenews(false)
    } else setlivenews((prev) => !prev)
  }
  const handleSource = () => {
    if (livenewstoggle === true) {
      setlivenews(false)
    } else setsourcenews((prev) => !prev)
  }

  return (
    <div className='news-container'>
      <div className='news-container-left'>
        <Sidebar />
      </div>
      <div className='news-container-right'>
        <AppNav />

        <div className='news-content'>
          <div className='news-content-left'>
            {!isMobile ? (
              <h1 className='historical-news-heading'>HISTORICAL NEWS</h1>
            ) : (
              <ul className='mobile-home-links'>
                <li
                  className={
                    mobileLink === 'hn' ? 'linkActive' : 'linkInactive'
                  }
                  onClick={() => setLink('hn')}
                >
                  HISTORICAL NEWS
                </li>
                <li
                  className={
                    mobileLink === 'ln' ? 'linkActive' : 'linkInactive'
                  }
                  onClick={() => setLink('ln')}
                >
                  LIVE NEWS
                </li>
                <li
                  className={
                    mobileLink === 'sn' ? 'linkActive' : 'linkInactive'
                  }
                  onClick={() => setLink('sn')}
                >
                  SOURCE NEWS
                </li>
              </ul>
            )}
            {mobileLink === 'hn' ? (
              <>
                <div className='historical-news-search'>
                  <input
                    style={{
                      backgroundColor: '#3D3C76',
                      border: 'none',
                      borderRadius: '0.6em',
                      color: '#66FFDA',
                      fontFamily: 'poppins',
                      width: '25%',
                      padding: '0.5em',
                    }}
                    id='date'
                    name='date'
                    type='date'
                    // value={bitchValues.date}
                    // onChange={e => {handleChange(e)
                    // console.log(e)}}
                  />
                </div>
                <div className={`historical-news-data ${loading?'loading_container':''}`}>
                  {!loading ? (
                    value?.map((item, index) => {
                      return <TwitchCapsule key={index} data={item.value} />
                    })
                  ) : (
                    <div style={{display:"flex", flexDirection:"column"}}>
                    <GridLoader color={'#D3EAFF'} loading={loading} size={25} />
                    </div>
                  )}
                </div>
              </>
            ) : (
              ''
            )}
            {mobileLink === 'ln' ? (
              <div className='news-content-right-mobile'>
                <div
                  className={`live-news ${
                    livenewstoggle ? 'flex-change-true' : 'flex-change-false'
                  }`}
                >
                <div className='live-news-container-active-m'>
                {!loading ? (
                    value?.map((item, index) => {
                      return <TwitchCapsule key={index} data={item.value} />
                    })
                  ) : (
                    <div style={{display:"flex", flexDirection:"column"}}>
                    <GridLoader color={'#D3EAFF'} loading={loading} size={25} />
                    </div>
                  )}
                </div>
              
                </div>
              </div>
            ) : (
              ''
            )}
            {mobileLink === 'sn' ? (
              <div className='news-content-right-mobile'>
                <div
                  className={`source-news ${
                    livenewstoggle ? 'flex-change-true' : 'flex-change-false'
                  }`}
                >
            <div className={`source-news-container-active-m ${loading ? 'loading_container':''}`}>
              {!loading ? (
                    value?.map((item, index) => {
                      return <TwitchCapsule key={index} data={item.value} />
                    })
                  ) : (
                    <div style={{display:"flex", flexDirection:"column"}}>
                    <GridLoader color={'#D3EAFF'} loading={loading} size={25} />
                    </div>
                  )}
              </div>
              
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='news-content-right'>
            <div
              className={`live-news ${
                livenewstoggle ? 'flex-change-true' : 'flex-change-false'
              }`}
            >
              <h1 className='live-news-heading'>LIVE NEWS</h1>
              <div className='live-news-minimize-expand' onClick={handleLive}>
                {livenewstoggle ? (
                  <div ref={minimaxliveref}>
                    <FiMaximize2 />
                  </div>
                ) : (
                  <div ref={minimaxliveref}>
                    <FiMinimize2 />
                  </div>
                )}
              </div>
              {
                livenewstoggle?'':
              <div className={`live-news-container-active ${loading?'loading_container':''}`}>
              {!loading ? (
                    value?.map((item, index) => {
                      return <TwitchCapsule key={index} data={item.value} />
                    })
                  ) : (
                    <div style={{display:"flex", flexDirection:"column"}}>
                    <GridLoader color={'#D3EAFF'} loading={loading} size={25} />
                    </div>
                  )}
              </div>
              }
            </div>
            <div
              className={`source-news ${
                sourcenewstoggle ? 'flex-change-true' : 'flex-change-false'
              }`}
            >
              <h1 className='source-news-heading'>SOURCE NEWS</h1>
              <div
                className='source-news-minimize-expand'
                onClick={handleSource}
              >
                {sourcenewstoggle ? (
                  <div ref={minimaxsourceref}>
                    <FiMaximize2 />
                  </div>
                ) : (
                  <div ref={minimaxsourceref}>
                    <FiMinimize2 />
                  </div>
                )}
              </div>
              {
                sourcenewstoggle?'':
              <div className={`source-news-container-active ${loading?'loading_container':''}`} >
              {!loading ? (
                    value?.map((item, index) => {
                      return <TwitchCapsule key={index} data={item.value} />
                    })
                  ) : (
                    <div style={{display:"flex", flexDirection:"column"}}>
                    <GridLoader color={'#D3EAFF'} loading={loading} size={25} />
                    </div>
                  )}
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default News
