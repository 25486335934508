import React, { useState } from "react";
import { sidebarIcons, sidebarSocials } from "../../dataArray";
import logo from "../../../assets/logo.png";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./sidebar.style.scss";
import Button from "../../button/button.component";
import { MdPowerOff } from "react-icons/md";
import {AuthContext} from '../../../context/context'
import { auth } from "../../../firebase/firebase.utils";
const Sidebar:React.FC = () => {
  const [toggleLogout, setLogout] = useState<boolean>(false)
  const navigate = useNavigate()
  return (
    <div className="app-sidebar">
      <div className="app-sidebar-logo-container">
        <img onClick={()=>navigate('/')} className="app-sidebar-logo" src={logo} alt="logo"></img>
      </div>
      <div className="app-sidebar-buttons-features">
        <div className="app-sidebar-buttons-pages">
          {sidebarIcons.map((el:any) => (
            <div className="app-sidebar-buttons-features-box" key={el.id}>
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "app-sidebar-buttons-features-box-true"
                    : "app-sidebar-buttons-features-box"
                }
                // className="app-sidebar-buttons-features-box to"
                to={el.link}
              >
                {el.icon}
              </NavLink>
              <Outlet/>
              <div className="sidebar-hover-state">{el.title}</div>
            </div>
          ))}
        </div>
        <div className="app-sidebar-buttons-socials">
          {sidebarSocials.map((el:any) => (
            <div
              className={`app-sidebar-buttons-socials-box `}
              style={{ color: el.color }}
              key={el.id}
            >
              {el.icon}
            </div>
          ))}
           <div className="logout" onClick={()=>setLogout(!toggleLogout)}><MdPowerOff/>
           </div>
        </div>
       
         <div className={toggleLogout?"logout-popup-active":"logout-popup-inactive"}>are you sure? 
           <Button
                  click={() =>{
                    navigate('/');
                    auth.signOut()
                  }
                  }
                  quality='red'
                  types='submit'
                >
                  logout
                </Button> </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Sidebar;
