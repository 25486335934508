import React from "react";
import "./Footer.scss";
const Footer:React.FC=()=> {
  return (
    <div className="footer-container">
      <p className="footer-text">
        Disclaimer: The U.S. Securities and Exchange Commission (SEC) requires
        the following legal notices: All material presented on Wall St Flow LLC
        is not to be regarded as investment advice, but for general
        informational purposes only. Trading does involve risk, so caution must
        always be utilized. We cannot guarantee profits or freedom from loss.
        You assume the entire cost and risk of any trading you choose to
        undertake. You are solely responsible for making your own investment
        decisions. Wall St Flow LLC, its representatives, its principals, its
        moderators, and its members, are NOT registered as securities
        broker-dealers or investment advisors either with the U.S. Securities
        and Exchange Commission or with any state securities regulatory
        authority. Wall St Flow LLC does not manage client assets in any way.
        All information/ideas provided on Wall St Flow LLC products and services
        are for educational purposes only.We recommend consulting with a
        registered investment advisor, broker-dealer, and/or financial advisor.
        Wall St Flow LLC requires that you fill out our electronic
        nonprofessional subscriber agreement form to receive data through our
        service. You will be redirected to this agreement after signing up.
        Should you sign up to our service you are fully aware that you will be
        required to fill out this agreement, and should you provide inaccurate
        information we may revoke or limit access to any Wall St Flow LLC
        service. This form can be seen in section 28 of our terms of service.
      </p>
      <div className="footer-copyright">
        © 2021 Wall St Flow LLC. All Rights Reserved.
      </div>
    </div>
  );
}

export default Footer;
