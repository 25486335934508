import React, {useEffect} from "react";
import "../../ooftile/ooftile.style.scss";
import "./equityandDarkTile.style.scss";
import UIfx from 'uifx';
import mp3File3 from '../../../../assets/dark.mp3'
interface props{
  time:string
  ticker: string
  quantity: string
  spot: string
  premium: string
}
const dark = new UIfx(mp3File3, {
  throttleMs: 600,
})

const EquityandDarkTileMobile:React.FC<props>=({time, ticker, quantity, spot, premium})=>{
  useEffect(() => {
    dark.play();
  },[]);
  return (
    <div className="ED-tile-container" style={{height:"7em", display:"flex", }}>
      <div className="flex1" style={{display:"flex", flexDirection:"column",gap:"1em"}}>
      <div className="flex1" style={{fontWeight:"normal", fontSize:"0.5rem", opacity:"0.6"}}>Time</div>
      <div className="flex1">{time}</div>
      </div>

      <div className="flex1" style={{display:"flex", flexDirection:"column",gap:"1em"}}>
      <div className="flex1 " style={{fontWeight:"normal", fontSize:"0.5rem", opacity:"0.6"}}>Ticker</div>
        <div className="blue-ticker-border">{ticker}</div>
      </div>
      <div className="flex1" style={{display:"flex", flexDirection:"column",gap:"1em"}}>
        <div className="flex1" style={{fontWeight:"normal", fontSize:"0.5rem", opacity:"0.6"}}>Spot</div>
      <div className="flex1 blue-item">{spot}</div>
      </div>
      <div className="flex1" style={{display:"flex", flexDirection:"column",gap:"1em"}}>
      <div className="flex1" style={{fontWeight:"normal", fontSize:"0.5rem", opacity:"0.6"}}>Quantity</div>
      <div className="flex1 ">{quantity}</div>
      </div>
      
      <div className="flex1" style={{display:"flex", flexDirection:"column",gap:"1em"}}>
      <div className="flex1" style={{fontWeight:"normal", fontSize:"0.5rem", opacity:"0.6"}}>Premium</div>
      <div className="flex1 green-item ">{premium}</div>
      </div>
    </div>
  );
}

export default EquityandDarkTileMobile;
