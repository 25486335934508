import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyAP-VwSsO_qyYWbPAtnubXJ7P-nv5_J1uA',
  authDomain: 'wallstflow.firebaseapp.com',
  projectId: 'wallstflow',
  storageBucket: 'wallstflow.appspot.com',
  messagingSenderId: '662922423671',
  appId: '1:662922423671:web:c26b1ef46e616230b68572',
  measurementId: 'G-BJZ88MQZRB',
}

export const createUserProfileDocument = async (
  userAuth: firebase.User | null,
  additionalData?: any
) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)

  const snapShot = await userRef.get()

  if (!snapShot.exists) {
    const createdAt = new Date()
    if (userAuth.phoneNumber) {
      try {
        await userRef.set({
          createdAt,
          phone: userAuth.phoneNumber,
        })
      } catch (err) {
        console.error(err)
      }
    } else {
      const { email }: any = userAuth
      if (additionalData.email) {
        try {
          await userRef.set({
            ...additionalData,
          })
        } catch (error: any) {
          console.log('error creating user', error.message)
        }
      } else {
        try {
          await userRef.set({
            email,
            createdAt,
            ...additionalData,
          })
        } catch (error: any) {
          console.log('error creating user', error.message)
        }
      }
    }
  }
  return userRef
}

firebase.initializeApp(config)

export const auth = firebase.auth()
export const firestore = firebase.firestore()

export default firebase
