import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TestimonialsCard from "./TestimonialsCard";
import "./Carousel.scss";

import { clientsArr } from "../../../dataArray";

const SimpleSlider:React.FC=()=> {

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 3000,
      slidesToShow: 2,
      slidesToScroll: 1,
      pauseOnHover: true,
      arrows: false,
    }
    const settingsMobile = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      arrows: false,
    };

    return (
      <div>
        <div className="slider slider-desktop">
          <Slider {...settings}>
            {clientsArr.map((client) => (
              <TestimonialsCard
                key={client.name}
                image={client.image}
                name={client.name}
                testimonial={client.testimonial}
              />
            ))}
          </Slider>
        </div>
        <div className="slider-mobile">
          <Slider {...settingsMobile}>
            {clientsArr.map((client) => (
              <TestimonialsCard
                key={client.name}
                image={client.image}
                name={client.name}
                testimonial={client.testimonial}
              />
            ))}
          </Slider>
        </div>
      </div>
    );
  }
export default SimpleSlider
