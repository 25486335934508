
import Lottie from "lottie-web";
import React, { useEffect, useRef } from "react";
import "./GiftAnim.Scss";
import giftimg from "../../../assets/gift.svg"
interface props{
  popUpToggle: string
  setPopUp: any
}
const GiftAnim:React.FC<props>=({popUpToggle, setPopUp})=>{
  const giftAnimRef = useRef<HTMLDivElement>(null);
  const giftLottie = Lottie;
  useEffect(() => {
    if(giftAnimRef.current) 
    giftLottie.loadAnimation(
      { 
        name:"gift",
        container: giftAnimRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: require("../../../lotties/gift.json"),
      },
    );
  }, [giftLottie]);
  const stopAnim =()=>{
    giftLottie.stop("gift")
  }
  const playAnim =()=>{
    giftLottie.play("gift")
  }
  // if(popUpToggle===null){
  // playAnim();
  // }
  // else
  // if(popUpToggle!==null){
  //   stopAnim();
  //   }
  

  return <div className="gift-anim-container" ref={giftAnimRef} onMouseEnter={stopAnim} onMouseLeave={playAnim}></div>;

}
export default GiftAnim;
