import React from "react";
import "./Card.scss";
interface props{
  heading:string
  text:string
  icon:any
}
const Card:React.FC<props>=({ heading, text, icon })=> {
  return (
    <div>
      <div className="box">
        <div className="card-heading">
          <div className="card-icon-container">
            <div className="card-icon">{icon}</div>
          </div>
          {heading}
        </div>
        <div className="card-text">{text}</div>
      </div>
    </div>
  );
}

export default Card;
