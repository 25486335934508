import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import "./SubCard.scss";
import Button from "../../../../button/button.component";
import {useNavigate} from "react-router-dom"
interface props{
  planType: string
  originalPrice: string
  planPrice: string
  duration:string
  navigateLink:string
  setIsShown:React.Dispatch<React.SetStateAction<boolean>>
}

const SubCard:React.FC<props>=({ planType, originalPrice, planPrice, duration, navigateLink, setIsShown })=> {
  const navigate=useNavigate();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  useEffect(() => {
    setIsShown(isHovered)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered])
  return (
    <div className="SubCard-container" 
    ref={hoverRef}
    >
      <div className="plan-type">{planType}</div>
      <div className="original-price">{originalPrice}</div>
      <div className="price-duration">
        {planPrice}
        <span className="duration">{duration}</span>
      </div>
      <div className="plan-text">No commitments. Cancel at any time</div>
      <span className="subCard-btn">
      <Button 
                  click={() =>
                    navigate(navigateLink)
                  }
                  quality='blue'
                  types='submit'
                >
                  subscribe
                </Button> 
      </span>
    </div>
  );
}

export default SubCard;


function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false); 

  const ref: any = useRef<T | null>(null);

  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);

  useEffect(
    () => {
      const node: any = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}
