import React from 'react'
import './aiCard.style.scss'
import AiTile from './aiTile/AiTile.component'
import firebase, { firestore } from '../../../firebase/firebase.utils'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { GridLoader } from 'react-spinners'
import AiCapsule from './aicapsule.component'
const AiCard: React.FC = () => {
  const query = firestore
    .collection('aiSignals')
    .orderBy('createdAt', 'desc')
    .limit(50)

  const [value, loading, error] = useCollectionData(query, {
    idField: 'id',
  })

  return (
    <div className='AI-container'>
      <div className='AI-header'>
        <div className='AI-textheading'>Wall St. AI</div>
        <div className='AI-capsules-container'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '90%',
            gap:"1em",
          }}
        >
     
          {!loading ? (
            value?.map((item, index) => {
              return (
                // <div key={index} style={{width:"100%"}}>
                <AiCapsule data={item.value} time={new Date(item.createdAt.seconds * 1000).toUTCString()}/>
                /* </div> */
                /* {item.value}{' '}
                  {new Date(item.createdAt.seconds * 1000).toUTCString()} */
              )
            })
          ) : (
            <GridLoader color={'#D3EAFF'} loading={loading} size={20} />
          )}
          
        </div>
        {/* <div className="AI-table">
          <div className="flex1 ">TIME</div>
          <div className="flex1">TICKER</div>
          <div className="flex1 ">CHANGE</div>
          <div className="flex1 ">%CHAN</div>
          <div className="flex1 ">VOL</div>
        </div>
        <div className={`AI-tiles ${loadingai ? 'loading_container':''} `}>
        
        {!loadingai
          ?
          aiData && aiData.map((data:any)=>
          <div key={data.id}>
            <AiTile time={data.time} change={data.change} ticker={data.ticker} perChange={data.perChange}
             volume={data.volume} />
            </div>
          )
        :
        <div style={{display:"flex", flexDirection:"column"}}>
        <GridLoader color={'#D3EAFF'} loading={loadingai} size={25} />
        </div>
        }
  
        </div> */}
      </div>
    </div>
  )
}

export default AiCard
