import React, { useState, useContext } from "react";
import "./Navbar.scss";
import DisplayLoginRegister from "../displayLoginRegister/DisplayLoginRegister";
import ArrowMenu from "../arrowmenu/ArrowMenu"
import { IoIosArrowBack } from "react-icons/io";
import { auth } from '../../../firebase/firebase.utils'
import {AuthContext} from '../../../context/context'
import { useNavigate } from "react-router-dom";
import GiftAnim from "../animation/GiftAnim"
 interface props{
   logo:any

 }
const Navbar:React.FC<props>=({ logo })=> {
  const [menuToggle, setMenuToggle] = useState<boolean>(false);
  const [popUpToggle, setPopUp] = useState<string>("");
  const {user, plans} = useContext(AuthContext)
  const navigate = useNavigate()
  const [first, setFirst] = useState<boolean>(true); //to check initial state for login and register
  const giftHandle = () =>{
    setPopUp('gift')
  }
  return (
    <div className="nav-bar-container">
      <nav className="nav-bar">
        <div className="nav-logo">
          <div className="nav-logo-icon-container">
            <img className="nav-logo-icon" src={logo} alt="logo" />
          </div>
          <div className="nav-logo-text">Wall St. Flow</div>
        </div>
        <div className="gift-mobile">
            <div className="gift-anim" onClick={giftHandle}>
              <GiftAnim popUpToggle={popUpToggle} setPopUp={setPopUp}/>
            </div>
          </div>
        <ul className="nav-links">
        <div className="gift">
            <div className="gift-anim" onClick={giftHandle}>
              <GiftAnim popUpToggle={popUpToggle} setPopUp={setPopUp}/>
            </div>
            <div className="gift-anim-text">Claim your free subscription</div>
          </div>
          {user  
          ?
          <li
            className="nav-links-login"
            onClick={() => {
              navigate('main')
              
            }}
          >
            get in
          </li>
          :
          <>
          <li
            className="nav-links-login"
            onClick={() => {
              setPopUp("login");
              setFirst(true);
            }}
          >
            login
          </li>
          <li
            className="nav-links-register"
            onClick={() => {
              setPopUp("register");
              setFirst(true);
            }}
          >
            register
          </li>
          </>
          }
        </ul>
        <div
          className="nav-arrow-menu"
          onClick={() => setMenuToggle(!menuToggle)}
        >
          
            <div className={`${menuToggle?"arrow-right":"arrow-left"}`}>
              <div className = "nav-burger-1"style={{width:"25px", height:"4px", backgroundColor:"#D3EAFF"}}></div>
              <div className = "nav-burger-2"style={{width:"25px", height:"4px", backgroundColor:"#D3EAFF"}}></div>
            </div>
        </div>
      </nav>
      <ArrowMenu
        setMenuToggle={setMenuToggle}
        menuToggle={menuToggle}
        popUpToggle={popUpToggle}
        setPopUp={setPopUp}
        first={first}
        setFirst={setFirst}
      />
      <DisplayLoginRegister
        popUpToggle={popUpToggle}
        setPopUp={setPopUp}
        first={first}
        setFirst={setFirst}
      />
    </div>
  );
}

export default Navbar;
