import React, { useEffect, useRef, useState } from "react";
import { gsap, Power3 } from "gsap";
import { auth } from "../../../firebase/firebase.utils";
import { fetchFromAPI } from '../../../firebase/helper';
import {Formik} from 'formik';
import "./Loginsecret.scss";
import Button from "../../button/button.component"
import Input from "../../input/input.component"
import * as Yup from 'yup'
import { useNavigate } from "react-router-dom";
import Classes from './signin.module.scss'
import { BsTelephone } from "react-icons/bs";
import '../login/signin/signin.style.scss'
interface props{
  popUpToggle:string
  setPopUp: any
  setFirst: any
  first:boolean
}
const validationSchema2 = Yup.object().shape({
  secret: Yup.string()
    .required('Required')
    .min(10, 'Key is too short - should be 10 chars minimum.'),
})

const Loginsecret:React.FC<props>=({ popUpToggle, setPopUp, setFirst, first })=>{
  const [wrong, setWrong] = useState<string | boolean>('')
  const navigate = useNavigate()
  const secretUser = async (key: string) => {
    try {
      const body = { key: key }
      const res = await fetchFromAPI('secretKey', { body })

      if (res === false) {
        setWrong(true)
      } else {
        auth
          .signInWithEmailAndPassword(res.email, res.password)
          .then((_) => console.log('user signed in'))
          .catch((err) => console.error(err))
        alert(
          'Your Account has been Approved and an Email with your credentials has been sent!'
        )
        setTimeout(() => {
          navigate('')
        }, 2000)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const loginSecretContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    gsap.to(
      loginSecretContainerRef.current,
      {
        opacity: 1,
        rotateY: 0,
        ease: Power3.easeOut,
        
      },
      
    );
  },[]);
  return (
    <div style={{transform:"scale(0.8)"}}>
    <div className="login-secret-container" ref={loginSecretContainerRef}>
      <div className="login-secret">
        <div className="login-top">
          <div className="login-title">
            <abbr className="login-title" title="this is the login">
              SECRET KEY
            </abbr>
          </div>
        </div>
        <Formik
              initialValues={{ secret: '' }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(async () => {
                  await secretUser(values.secret)
                  setSubmitting(false)
                }, 100)
              }}
              validationSchema={validationSchema2}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props

                return (
                  <div style={{ width: '100%' }}>
                    <form
                      className="signin_form"
                      onSubmit={handleSubmit}
                      noValidate={true}
                    >
                      <Input
                        id='secret'
                        name='secret Key'
                        type='text'
                        value={values.secret}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors.secret || `${wrong && 'Wrong Code'}`}
                        touched={touched.secret}
                        extra='signin-input'
                        blank='form'
                      />
                      <div className="secret-button">
                      <Button types='submit' disabled={isSubmitting}>
                        Sign In{isSubmitting && '...'}
                      </Button>
                      </div>
                    </form>
                  </div>
                )
              }}
            </Formik>
        <div className="login-phone-no">
    
        </div>
        <div className="login-btn-container">
        
          <div
            className="or-use-email"
            onClick={() => {
              setPopUp("login");
              setFirst(false);
            }}
          >
            or use{" "}
            <span style={{ fontWeight: "bold", marginLeft: "0.4em" }}>
              Email
            </span>
          </div>
          <div
            className="or-use-phone"
            onClick={() => {
              setPopUp("loginphone");
            }}
          >
            or use{" "}
            <span style={{ fontWeight: "bold", marginLeft: "0.4em" }}>
              Phone no.
            </span>
          </div>
        </div>
        {/* <div className="forgot-password">Forgot Password?</div> */}
        <div className="extras">
          <div className="forgot-password-text">
            {" "}
            don't have an account yet?{" "}
            <span
              className="register-here"
              onClick={() => {
                setPopUp("register");
                setFirst(false);
              }}
            >
              REGISTER HERE
            </span>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Loginsecret;
