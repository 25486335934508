import { auth } from '../firebase/firebase.utils'

const API = 'https://us-central1-wallstflow.cloudfunctions.net/api'
const API2 = 'https://us-central1-wsbible-caada.cloudfunctions.net/api'

// A helper function to fetch data from the api

export async function fetchFromAPI(endpointURL: string, opts: any) {
  const { method, body }: any = { method: 'POST', body: null, ...opts }
  const user = auth.currentUser
  const token = user && (await user.getIdToken())

  const res = await fetch(`${API}/${endpointURL}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res.json()
}
export async function fetchFromAPI2(endpointURL: string, opts: any) {
  const { method, body }: any = { method: 'POST', body: null, ...opts }
  // const user = auth.currentUser
  // const token = user && (await user.getIdToken())

  const res = await fetch(`${API2}/${endpointURL}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${''}`,
    },
  })

  return res.json()
}
