import React, { useContext, useEffect, useState } from 'react'
import './watchlist.style.scss'
import { BiSearchAlt } from 'react-icons/bi'
import WatchlistTile from './watchlistTiles/WatchlistTile.component'
import { IoMdArrowDropup } from 'react-icons/io'
import { AuthContext } from '../../../context/context'
import { firestore } from '../../../firebase/firebase.utils'
import { useDocumentData } from 'react-firebase-hooks/firestore'
interface props {
  setWatchlist: any
  toggleWatchlist: boolean
}
const Watchlist: React.FC<props> = ({ setWatchlist, toggleWatchlist }) => {
  const [search, setSearch] = useState<string>('')
  const { user } = useContext(AuthContext)

  const query = firestore.collection('watchlist').doc(user?.uid)

  const [value, loading, error] = useDocumentData(query, {
    idField: 'id',
  })

  return (
    <div className='watchlist-container'>
      <div className='watchlist-search-container'>
        <div className='watchlist-search'>
          <input
            className='watchlist-search-field'
            type='text'
            placeholder='SEARCH'
            value={search}
            onChange={(e) => setSearch(e.target.value.toUpperCase())}
          />
          {/* <span className='watchlist-search-button'>
            <BiSearchAlt />
          </span> */}
        </div>
      </div>
      <div className='watchlist-tiles'>
        {search && (
          <WatchlistTile
            ticker={search}
            action={!error ? value?.ticker.includes(search) : false}
          />
        )}
        {value?.ticker.length > 0 &&
          value?.ticker.map((item: any, index: number) => {
            return <WatchlistTile key={index} ticker={item} action={true} />
          })}
      </div>
      <div
        className='watchlist-arrow'
        onClick={() => {
          setWatchlist(!toggleWatchlist)
        }}
      >
        <IoMdArrowDropup />
      </div>
    </div>
  )
}

export default Watchlist
