import React, { useContext, useEffect, useRef, useState } from 'react'
import { fetchFromAPI } from '../../firebase/helper'
import { AuthContext } from '../../context/context'
import './checkout.new.scss'
import { Formik } from 'formik'
import qs from 'qs'
import * as Yup from 'yup'
import Input from '../../components/input/input.component'
import { useLocation, useNavigate } from 'react-router-dom'
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js'
import { ReactComponent as Gpay } from '../../assets/gpay.svg'
import { ReactComponent as Apay } from '../../assets/applepay.svg'
import SignIn from '../../components/landingpageComponents/login/signin/signin.component'
import { FaCcVisa } from 'react-icons/fa'

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
})

export const intel: any = {
  price_1IHLAoGDws3elXsYccMjqFRg: ['Standard Monthly Plan', '29'],
  price_1IHLAoGDws3elXsYccMjqFRh: ['Standard 3 Months Plan', '150'],
  price_1IHLCGGDws3elXsYvVTncKzv: ['Standard Annual Plan', '600'],
}

const Checkout: React.FC = () => {
  const paypal = useRef<any>()
  const [url, setUrl] = useState('')
  const [toggleStripe, setToggleStripe] = useState(false)
  const id: any = qs.parse(useLocation().search, { ignoreQueryPrefix: true }).id
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState<null | string | undefined>(null)
  const [paidFor, setPaidFor] = useState(false)
  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const element = useElements()
  const { user } = useContext(AuthContext)
  const [plan, setPlan] = useState<string>(id)
  const navigate = useNavigate()

  const createOptions = {
    style: {
      base: {
        fontSize: '18px',
        color: '#fff',
        iconColor: '#3D3C76',
        fontSmoothing: 'antialiased',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '300',
        '::placeholder': {
          color: '#3D3C76',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  }

  // handle the submission of card details
  const handleSubmit = async (email: string) => {
    setLoading(true)

    const cardElement = element?.getElement(CardElement)

    if (cardElement && stripe) {
      // create Payment Method element
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      if (error) {
        setError(error.message)
        setLoading(false)
        return
      }

      const subscription = await fetchFromAPI('subscriptions', {
        body: {
          plan,
          payment_method: paymentMethod?.id,
          email,
        },
      })

      const { latest_invoice } = subscription

      if (latest_invoice.payment_intent) {
        const { client_secret, status } = latest_invoice.payment_intent

        if (status === 'requires_action') {
          const { error: confirmationError } = await stripe.confirmCardPayment(
            client_secret
          )
          if (confirmationError) {
            console.error(confirmationError)
            setError('unable to confirm card payment')
            setLoading(false)
            return
          }
        }

        //success
        setSucceeded(true)
      }
      setLoading(false)
      setPlan('')
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        navigate('success')
      }, 3000)
    }
  }

  const handleCheckout = async () => {
    const product = { quantity: 1, price: id }

    const body = { line_items: [product] }
    const { id: sessionId } = await fetchFromAPI('checkouts/', {
      body,
    })
    const { error }: any = await stripe?.redirectToCheckout({
      sessionId,
    })
    if (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const _ = (window as any).paypal
      .Buttons({
        style: {
          layout: 'vertical',
          color: 'gold',
          shape: 'rect',
          label: 'paypal',
        },
        createOrder: (data: any, actions: any) => {
          return actions.order.create({
            purchase_units: [
              {
                description: intel[id][0],
                amount: {
                  currency_code: 'USD',
                  value: parseFloat(intel[id][1]),
                },
              },
            ],
          })
        },
        onApprove: async (data: any, actions: any) => {
          const order = await actions.order.capture()
          setPaidFor(true)

          const body = {
            email: order.payer.email_address,
            price: intel[id][1],
            type: intel[id][0],
          }
          try {
            const _ = await fetchFromAPI('paypalApproval', { body })
          } catch (err) {
            console.error(err)
          }
          alert(
            'Your Payment was a success! Redirecting you to the main page...'
          )
          navigate('main')

          console.log(order.payer.email_address)
        },
        onError: (err: any) => {
          setError(err)
          console.error(err)
        },
      })
      .render(paypal.current)
  }, [id, navigate])

  useEffect(() => {
    if (user) {
      const fetcher = async () => {
        const body = {
          price: intel[id][1],
          desc: intel[id][0],
        }
        try {
          const res = await fetchFromAPI('checkoutCrypto/', { body })
          setUrl(res.hosted_url)
        } catch (err) {
          console.log(err)
        }
      }
      fetcher()
    }
  }, [user, id])

  if (!id) {
    navigate('')
    return <>redirect</>
  }
  const handleClick = async () => {}

  return (
    <div className="checkout">
   
      <div className="container">
        <h1 className="title">Checkout</h1>
        <div className="product_cont">
          <div className="product_desc">
            <h3 style={{fontWeight:"bold", textAlign:"center"}}>Plan Details</h3>
            
           
            {plan === 'price_1IHLAoGDws3elXsYccMjqFRg' ? (
              <>
                  <div style={{fontSize:"1rem", textAlign:"center"}}>
                  subscription to standard
                  Monthly Plan
                  </div>
              <div className="checkout_prod_container">
          
                <h2 className="checkout_prod_mid">
                  $29
                  </h2>
                 
                  <div className="checkout_prod_bottom">
                 
                  <span className="offer">
                    ($31 off <span>Special Offer</span>)
                  </span>
               
               
                <h3 className="original">Then $60 per month</h3>
                </div>
                </div>
               
                
                
               
              </>
            ) : plan === 'price_1IHLAoGDws3elXsYccMjqFRh' ? (
              <>
              <div className="checkout_prod_container" style={{flexDirection:"column"}}>
                <h2>
                  $120
                 
                </h2>
                <span className="offer">
                    ($10 off <span>Special Offer</span>)
                  </span>
                </div>
                <h3 className="original">Then $130 every 3 months</h3>
              </>
            ) : (
              <div className="checkout_prod_container" >
              <h2>
                ${intel[id][1]}
                
              </h2>
              <span className="duration">
                  {intel[id][1] === '60'
                    ? '/every month'
                    : intel[id][1] === '150'
                    ? '/every 3 months'
                    : '/year'}
                </span>
              </div>
            )}
            </div>
         
          
          <div className="payment_cont">
            <div className="heading">
              <span className="round_number">1</span>
              <h2>Let's Verify you!</h2>
            </div>
            {user ? (
              <div className="signedin_cont">
                <h2 style={{fontSize:"1rem", color:"#66FFDA"}}>You are Verified! 👍</h2>
              </div>
            ) : (
              <div className="signin_cont">{<SignIn />}</div>
            )}
            <div className="heading">
              <span className="round_number">2</span>
              <h2>Confirm Payment</h2>
            </div>
            {!toggleStripe && (
              <div className="payment_types_container">
                <div
                  className="card_way"
                  style={!user ? { cursor: 'not-allowed' } : undefined}
                  onClick={user ? () => setToggleStripe(true) : undefined}
                >
                  <span>
                    Pay with Card
                    <FaCcVisa />
                  </span>
                </div>
                <div
                  className="paypal_way"
                  style={!user ? { cursor: 'not-allowed' } : undefined}
                >
                  <div
                    ref={paypal}
                    style={!user ? { cursor: 'not-allowed' } : undefined}
                  />
                </div>
                <div
                  className="crypto_way"
                  style={!user ? { cursor: 'not-allowed' } : undefined}
                  onClick={user ? handleClick : undefined}
                >
                  <a href={url}>₿ Pay with Crypto</a>
                </div>
              </div>
            )}
            {toggleStripe && (
              <>
                <Formik
                  initialValues={{ email: '' }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      handleSubmit(values.email)
                      setSubmitting(false)
                    }, 100)
                  }}
                  validationSchema={validationSchema}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    } = props

                    return (
                      <form
                        className="checkout_form"
                        onSubmit={handleSubmit}
                        id='payment-form'
                        noValidate={true}
                      >
                        <Input
                          id='email'
                          name='email'
                          type='text'
                          value={values.email}
                          onChange={(e) => {
                            handleChange(e)
                          }}
                          blank='checkout'
                          onBlur={handleBlur}
                          errors={errors.email}
                          touched={touched.email}
                        />
                        <CardElement
                          className="card_input"
                          id='card-element'
                          options={createOptions}
                        />
                        <button
                          className="btn_pay"
                          disabled={loading || !user}
                          id='submit'
                        >
                          <span id='button-text'>
                            {loading ? (
                              <div
                                className="spinner"
                                id='spinner'
                              ></div>
                            ) : (
                              'Subscribe'
                            )}
                          </span>
                        </button>
                        {/* Show any error that happens when processing the payment */}
                        {error && (
                          <div className="card_error" role='alert'>
                            {error}
                          </div>
                        )}
                        {/* Show a success message upon completion */}
                        <p
                          className={
                            succeeded
                              ? "result_message"
                              : `result_message hidden`
                          }
                        >
                          You are Subscribed! Redirecting...
                        </p>
                      </form>
                    )
                  }}
                </Formik>
                <div className="checkout_type">
                  or{' '}
                  <span
                    onClick={user && !loading ? handleCheckout : undefined}
                    style={
                      !user || loading ? { cursor: 'not-allowed' } : undefined
                    }
                  >
                    <Gpay className="gpay logo" />
                    <Apay className="apay logo"  />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout
