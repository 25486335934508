import React,{useRef, useEffect} from 'react'
import SignIn from './signin/signin.component'
import "./Loginphone.scss";
import { FiArrowRightCircle } from 'react-icons/fi'
import {gsap, Power3} from "gsap"
interface props{
    popUpToggle:string
    setPopUp:any
    first:boolean
    setFirst:any
}
const Loginphone:React.FC<props> = ({popUpToggle, setPopUp, setFirst}) => {
 
    const loginContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    gsap.to(
      loginContainerRef.current,
      {
        opacity: 1,
        rotateY: 0,
        ease: Power3.easeOut,
      },
      
    );
  },[]);
    return (
      <div style={{transform:"scale(0.8)"}}>
        <div ref={loginContainerRef} className='login-phone-container' >
          <div className='login-header'>LOGIN</div>
          <div><SignIn /></div>
          <div  onClick={() => {
                  setPopUp("login");
                  setFirst(false);
                }}>Or use <span style={{fontWeight: "bold", cursor:"pointer"}}>EMAIL
        </span></div>
        <div className="forgot-password">Forgot Password?</div>
          <div className="extras">
            <div className="forgot-password-text">
              {" "}
              don't have an account yet?{" "}
              <span
                className="register-here"
                onClick={() => {
                  setPopUp("register");
                  setFirst(false);
                }}
              >
                REGISTER HERE
              </span>
            </div>
            <div className="if-purchased">
              {" "}
              <div
                className="login-bottom-bar"
                style={{
                  margin: "2em auto",
                  width: "100%",
                  height: "0.4em",
                  borderRadius: "10px",
                  backgroundColor: "white",
                }}
              ></div>
              <div
                className="bundle-package"
                onClick={() => {
                  setPopUp("loginsecret");
                }}
              >
                {" "}
                OR Bundle Users{" "}
                <div className="bundle-package-arrow">
                  <FiArrowRightCircle />
                </div>
              </div>
            </div>
          </div>
      </div>
      </div>
    )
}

export default Loginphone
