import client1 from '../assets/1.png'
import client2 from '../assets/2.png'
import client3 from '../assets/3.png'
import client4 from '../assets/4.png'
import client5 from '../assets/5.png'
import client6 from '../assets/6.png'
import { VscGraphLeft } from 'react-icons/vsc'
import { AiOutlineAlert } from 'react-icons/ai'
import { HiOutlineNewspaper } from 'react-icons/hi'
import { RiStockLine } from 'react-icons/ri'
import { BsClipboardData } from 'react-icons/bs'
import { IoIosStats } from 'react-icons/io'
import { BiFilterAlt } from 'react-icons/bi'
import { BiBook } from 'react-icons/bi'
import { HiHome } from 'react-icons/hi'
import { RiChatHistoryFill } from 'react-icons/ri'
import { MdInsertChart } from 'react-icons/md'
import { HiNewspaper } from 'react-icons/hi'
import { RiUser5Fill } from 'react-icons/ri'
import { AiOutlineTwitter } from 'react-icons/ai'
import { AiFillInstagram } from 'react-icons/ai'
import { MdPowerOff } from 'react-icons/md'
import { HiOutlineMail } from 'react-icons/hi'
import { RiLockPasswordLine } from 'react-icons/ri'

export const appCardData = [
  {
    heading: 'Flow sentiment',
    subHeading: 'BULLISH',
    progress: 7,
    strokeColor: '#82C571',
    steps: 100,
  },
  {
    heading: 'Put to call',
    subHeading: '0.681',
    progress: 70,
    strokeColor: '#82C571',
    steps: 110,
  },
  {
    heading: 'Call Flow',
    subHeading: '1,149,489',
    progress: 70,
    strokeColor: '#82C571',
    steps: 100,
  },
  {
    heading: 'Put Flow',
    subHeading: '783,241',
    progress: 40,
    strokeColor: '#C57171',
    steps: 100,
  },
]
export const oofTable = [
  'TIME',
  'TICKER',
  'PRICE',
  'TYPE',
  'STRIKE',
  'EXPIRY',
  'DTE',
  'DETAILS',
  'PREMIUM',
  'O-INT',
  'IV',
]
export const loginInput = [
  {
    id: 'login-email-icon',
    propClass: 'login-email',
    icon: <HiOutlineMail />,
    inputType: 'email',
    placeHolderText: 'EMAIL',
  },
  {
    id: 'login-password-icon',
    propClass: 'login-password',
    icon: <RiLockPasswordLine />,
    inputType: 'email',
    placeHolderText: 'EMAIL',
  },
]
export const sidebarIcons = [
  { id: 'sidebar-main', icon: <HiHome />, link: '/main', title: 'home' },
  {
    id: 'sidebar-history',
    icon: <RiChatHistoryFill />,
    link: '/history',
    title: 'historical data',
  },
  {
    id: 'sidebar-charts',
    icon: <MdInsertChart />,
    link: '/charts',
    title: 'live charts',
  },
  {
    id: 'sidebar-news',
    icon: <HiNewspaper />,
    link: '/news',
    title: 'news section',
  },
  {
    id: 'sidebar-profile',
    icon: <RiUser5Fill />,
    link: '/profile',
    title: 'user profile',
  },
]
export const sidebarSocials = [
  { id: 'sidebar-twitter', icon: <AiOutlineTwitter /> },
  {
    id: 'sidebar-insta',
    icon: <AiFillInstagram />,
  },
]
export const benefitsArr = [
  'Real-Time Options Order Flow',
  'Dark & Lit Pool Prints',
  'Historical Options Order Flow',
  'Algorithmic Flow Alerts',
  'Real-Time News + Sentiment',
  'Historical News + Sentiment',
  'Unusual Options Activity',
  'Advanced Flow Filtering',
  'Quantitative Stock Reports',
  'Institutional Order Tracking',
  'Options Profit Calculator',
  'Daily Gainers & Losers',
  'Desktop Notifications',
  'Analyst Ratings',
  'Daily Flow Insights',
  '24/7 Customer Support',
]
export const keyFeaturesArr = [
  {
    id: '1',
    icon: <VscGraphLeft />,
    heading: 'Options Order Flow',
    text: 'Trades are displayed as they arrive in real-time, providing you with all of the necessary data to make knowledgeable, well-informed decisions when trading.',
  },
  {
    id: '2',
    icon: <AiOutlineAlert />,
    heading: 'Algorithmic Alerts',
    text: "With our algorithmically curated alerts, you can be confident that you're kept up to date with the state of the market and its trends forspecific tickers. ",
  },
  {
    id: '3',
    icon: <HiOutlineNewspaper />,
    heading: 'News + Sentiment',
    text: 'Worldwide events can significantly impact the price of a stock. We display news with related tickers and sentiment from 50+ sources for your convenience!',
  },
  {
    id: '4',
    icon: <RiStockLine />,
    heading: 'Stock Reports',
    text: 'Evaluate stocks, search trade ideas, and analyze trends using our consolidated, 12-page quantitative reports for all U.S. stocks on the major exchanges.',
  },
]
export const identifyArr = [
  {
    id: 'Historical Data',
    icon: <BsClipboardData />,
    heading: 'Historical Data',
    text: 'Utilize prior options data to make informed decisions in the future.',
  },
  {
    id: 'Market-Wide Statistics',
    icon: <IoIosStats />,
    heading: 'Market-Wide Statistics',
    text: 'Track the current bullish/bearish sentiment of the market.',
  },
  {
    id: 'Customizable Filtering',
    icon: <BiFilterAlt />,
    heading: 'Customizable Filtering',
    text: 'Exclude unwanted options data using our advanced filter settings.',
  },
  {
    id: 'Educational Content',
    icon: <BiBook />,
    heading: 'Educational Content',
    text: 'Improve your knowledge with our concise educational articles.',
  },
]
export const subCardArr = [
  {
    id: 'Standard Monthly Plan',
    planType: 'Standard Monthly Plan',
    originalPrice: '$99.99/month',
    planPrice: '$60',
    duration: '/month',
    navigateLink: 'checkout?id=price_1IHLAoGDws3elXsYccMjqFRg',
  },
  {
    id: 'Standard Annual Plan',
    planType: 'Standard Annual Plan',
    originalPrice: '$999.99/year',
    planPrice: '$600',
    duration: '/year',
    navigateLink: 'checkout?id=price_1IHLCGGDws3elXsYvVTncKzv',
  },
  {
    id: 'Three Months Plan',
    planType: 'Three Months Plan',
    originalPrice: '$179.99/3 months',
    planPrice: '$150',
    duration: '/3 months',
    navigateLink: 'checkout?id=price_1IHLAoGDws3elXsYccMjqFRh',
  },
]
export const clientsArr = [
  {
    id: 'Liam Miller',
    image: client1,
    name: 'Liam Miller',
    testimonial:
      "I love Wall St Flow because it allows me to see things I shouldn't be able to see, where the BIG Boy money is flowing, first trade I copied already up $12,345 on the day. Thanks Admins !",
  },
  {
    id: 'Ivor Watson',
    image: client2,
    name: 'Ivor Watson',
    testimonial:
      'I never knew where to put my money when I looked at stocks, I would kinda just put it all over the place and hope it would rise, with Wall St. Flow I invest my money where the banks are investing theirs, which already that alone is insane! Im very happy, thanks all!',
  },
  {
    id: 'Fiona Smith',
    image: client6,
    name: 'Fiona Smith',
    testimonial:
      "I just wanted to thank you for creating Wall St Flow. You guys are doing a great job filtering the data and providing us with information that's absolutely necessary for day traders. My performance has since improved drastically!",
  },
  {
    id: 'Mike Bailey',
    image: client3,
    name: 'Mike Bailey',
    testimonial:
      'Wall St Flow is good for average or advanced traders, they offer tools where you can see where the big money is flowing, so far my experience has been great with their easy to find functions and outstanding hospitality.',
  },
  {
    id: 'Louise Adams',
    image: client4,
    name: 'Louise Adams',
    testimonial:
      "Man these people just keep adding new things to the site, that's why I stay, they literally have done 5 updates since I joined and all the updates have enhanced my experience,  they just keep making it easier to make money, its safe to say they are the BEST in this industry.",
  },
  {
    id: 'Harry Manson',
    image: client5,
    name: 'Harry Manson',
    testimonial:
      "Great resource I use it on a day to day basis, its helped improved my rational to a high extent. I give it 9.8 stars out of 10 just because I am picky and I have extremely high expectations. Still overall the best service I've received.",
  },
]
