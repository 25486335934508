import React, { useRef, useEffect } from "react";
import BenefitsCard from "./BenefitsCard/BenefitsCard";
import "./Packages.scss";
import { ScrollTrigger } from "gsap/all";
import { gsap, Power3 } from "gsap";
import { benefitsArr } from "../../../dataArray";
function Packages() {
  const packagesRef = useRef<HTMLDivElement>(null);
  const benefitsRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   gsap.from(
  //     packagesRef.current,
  //     {
      
  //       opacity:0,
  //       y: 100,
  //       ease: Power3.easeOut,
  //       scrollTrigger: {
  //         trigger: ".identify",
  //         scrub: 1,
  //         start: "60%",
  //         end: "+=100px",
  //         toggleActions: "restart complete none reverse",
  //         onLeaveBack: (self) => self.disable(),
  //       },
  //     },
      
  //   );
  useEffect(() => {
    ScrollTrigger.matchMedia({
	
      // desktop
      "(min-width: 1200px)": function() {gsap.from(
        packagesRef.current,
        {
          opacity:0,
          y: 100,
          ease: Power3.easeOut,
          duration: 1,
          scrollTrigger: {
            trigger: ".identify",
            start: "60%",
            end: "+=100px",
            toggleActions: "restart complete none reverse",
            onLeaveBack: (self) => self.disable(),
            scrub: 1,
          },
        },
      )},
      //
      // phone
      "(max-width: 1199px)": function() {gsap.from(
        packagesRef.current,
        {
          opacity:0,
          y: 100,
          ease: Power3.easeOut,
          duration: 1,
          // scrollTrigger: {
          //   trigger: ".identify",
          //   start: "60%",
          //   end: "+=100px",
          //   toggleActions: "restart complete none reverse",
          //   onLeaveBack: (self) => self.disable(),
          //   scrub: 1,
            
          // },
        },
      )}
      //
    })
  },[]);
  return (
    <div className="packages-container" ref={packagesRef}>
      <div className="packages">
        <div className="packages-text">
          <h1 className="packages-heading">Fair, Simple Pricing For All</h1>
          <p className="packages-subHeading">
            Select a plan that suits your budget from the following list of
            pricing plans
          </p>
        </div>
        <div className="packages-card">
          <div className="packages-benefits" ref={benefitsRef}>
            <BenefitsCard benefitsArr={benefitsArr} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Packages;
