import React, { useState, useRef, useContext, useEffect, useLayoutEffect } from 'react'
import firebase, { firestore } from "../../../firebase/firebase.utils"
// import './chat.module.scss'
import "./chat.style.new.scss"
import { useCollectionData } from 'react-firebase-hooks/firestore'
import ChatDisplay from '../chatDisplay/chatDisplay.component'
import GridLoader from 'react-spinners/ClipLoader'
import { IoIosSend } from 'react-icons/io'
import { FaDiscourse } from 'react-icons/fa'
import { AuthContext } from '../../../context/context'
import  {IoMdArrowDropup} from 'react-icons/io'
import {BsFillArrowRightCircleFill} from 'react-icons/bs'
import {BsFillChatRightDotsFill} from 'react-icons/bs'
import {ImCross} from "react-icons/im"
import {gsap, Power3, Elastic} from 'gsap';
interface props {
  click: any
  sett: any
}

const Chat: React.FC<props> = ({ click, sett }) => {
  const chatref = useRef<HTMLDivElement>(null)
  const chatcontref = useRef<HTMLDivElement>(null)
  const chatheaderref = useRef<HTMLDivElement>(null)
  const chatinputref = useRef<HTMLDivElement>(null)
  const switchref = useRef<HTMLDivElement>(null)
  const switchiconref = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    gsap.fromTo(switchref.current, 
      {  
        
        x:"-10px" ,
        y:"10px" ,
        rotate:"20deg",
        opacity:"0",
        delay:1,
        ease: Power3.easeInOut,
      },
      {  
        x:"0px" ,
        y:"-10px" ,
        rotate:"0deg",
        opacity:1,
        ease: Power3.easeInOut,
      },
    );

    gsap.fromTo(switchiconref.current, 
      {  
        scaleY:"2",
        x:"-10px" ,
        y:"10px" ,
        rotate:"40deg",
        opacity:"0",
        ease: Power3.easeInOut,
      },
      {  scaleY:"1",
        x:"0px" ,
        y:"0px" ,
        rotate:"0deg",
        opacity:1,
        ease: Elastic.easeInOut,
      },
      
    );

    // gsap.fromTo(chatref.current, 
    //   {  
    //     boxShadow:"0px 0px 0px 0px rgba(0, 0, 0, 0.4)",
    //   },
    //   {  
    //     boxShadow:"4px 5px 8px 5px rgba(0, 0, 0, 0.4)",
    //     delay:"2s"
    //   },
    // );
    gsap.from(chatcontref.current, 
      {  
        x:"-100px" ,
        opacity:"0",
        ease: Power3.easeInOut,
      },
    );
    gsap.from(chatheaderref.current, 
      {  
        x:"-100px" ,
        opacity:"0",
        ease: Power3.easeInOut,
      },
    );
    gsap.from(chatinputref.current, 
      {  
        x:"-100px" ,
        opacity:"0",
        ease: Power3.easeInOut,
      },
      
    );
    gsap.from(chatinputref.current, 
      {  
        y:"-30px" ,
        ease: Power3.easeInOut,
      },
  )}
  );
  
  const [fromValue, setFormValue] = useState('')
  const [toggle, setToggle] = useState(false)

  const { user } = useContext(AuthContext)
  const { uid }: any | null = user
  const dummy = useRef<HTMLDivElement>(null)

  const messageRef = firestore.collection('messages')
  const query = messageRef.orderBy('createdAt') //limit

  const [messages, loading] = useCollectionData<any>(query, {
    idField: 'id',
  })
 
  const sendMessage = async (e: any) => {
    e.preventDefault()
    if (fromValue !== '') {
      const userData = await firestore.collection('users').doc(uid).get()
      const { username }: any = userData.data()
      console.log('username', username)

      await messageRef.add({
        message: fromValue,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        username,
      })
      setFormValue('')
      dummy.current?.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'end',
      })
    }
  }

  const handleSwitch = () => {
    setToggle((prev) => !prev)
  }

  return (
    <div className={`chat_cont `}>
    {/* <div className={Classes.chat} style={!toggle ? { height:'0'} : {height:'85vh'}}> */}
    <div className={`chat ${toggle?"chatActive":"chatInactive"}`} ref={chatref}>
      <div className="switch" onClick={handleSwitch} ref={switchref}>
        {!toggle?<div className='switch-icon' ref={switchiconref}><BsFillChatRightDotsFill/></div>:<div ref={switchiconref} className='switch-icon'><ImCross/></div>}
        {/* <span className={`${toggle?"arrowActive":"arrowInactive"}`}> <IoMdArrowDropup /></span> */}
        <span className='chat-hover'>chat</span>
      </div>
      {toggle && (
        <>
        <h1 ref={chatheaderref} style={{display:"flex",justifyContent:"center", padding:"1em", backgroundColor:"#18174A",borderRadius:"0.6em 0.6em 0 0",  boxShadow: "4px 0px 8px 2px rgba(0, 0, 0, 0.4)",zIndex:"1" }}>Chat</h1>
          <div
            className={`chat_container ${
              loading && "loading"
            } ${toggle?"chat_container_active":"chat_container_inactive"}`}
           ref = {chatcontref}>
             
            <GridLoader color={'#26667a'} loading={loading} size={80} />
            {messages &&
              messages.map((mes) => <ChatDisplay key={mes.id} mes={mes} />)}
            <div ref={dummy}></div>
          </div>
          <div ref = {chatinputref} >
          <form onSubmit={sendMessage} >
            <input 
              placeholder='Type here...'
              onChange={(e) => setFormValue(e.target.value)}
              value={fromValue}
            />
            <button type='submit'>
              <IoIosSend className="logo" />
            </button>
          </form>
          </div>
        </>
      )}
    </div>
    </div>
  )
}

export default Chat
