import React from 'react'

import Classes from './input.module.scss'
import { IoIosLock } from 'react-icons/io'
import { IoIosMail } from 'react-icons/io'
import { IoMdPerson } from 'react-icons/io'
import { IoMdContact } from 'react-icons/io'
import { IoMdCall } from 'react-icons/io'
import { IoIosSearch } from 'react-icons/io'

interface props {
  id: string
  type: string
  errors?: string | undefined
  extraCont?: string
  extra?: string
  touched?: boolean | undefined
  name: string
  value: string
  blank?: boolean | string | undefined
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
  onBlur?: (e: React.FocusEvent<any>) => void | undefined
}

const Input: React.FC<props> = ({
  errors,
  extraCont,
  extra,
  touched,
  name,
  blank,
  value,
  ...otherProps
}) => {
  let logo = null

  if (name === 'email') {
    logo = <IoIosMail className={Classes.logo} />
  } else if (name === 'password') {
    logo = <IoIosLock className={Classes.logo} />
  } else if (name === 'firstName') {
    logo = <IoMdContact className={Classes.logo} />
  } else if (name === 'lastName') {
    logo = <IoMdContact className={Classes.logo} />
  } else if (name === 'confirmPassword') {
    logo = <IoIosLock className={Classes.logo} />
  } else if (name === 'username') {
    logo = <IoMdPerson className={Classes.logo} />
  } else if (name === 'phone') {
    logo = <IoMdCall className={Classes.logo} />
  } else if (name === 'search') {
    logo = <IoIosSearch className={Classes.logo} />
  }
  return (
    <div className={Classes.input}>
      <div
        className={`${Classes.container} ${
          blank
            ? blank === 'checkout'
              ? Classes.checkout
              : blank === 'form'
              ? Classes.form
              : Classes.blank
            : ''
        }`}
      >
        {logo}
        <input
          {...otherProps}
          value={value}
          className={`${errors && touched && Classes.error} ${
            name === 'search' ? Classes.search : ''
          } ${Classes.custom_input} ${extra && Classes[extra]}`}
          required={true}
          placeholder={name === 'phone' ? '+180735xxxxx' : name.toUpperCase()}
          formNoValidate={true}
        />
        {/* <span className='placeholder'>{name}</span> */}
      </div>
      {errors && touched && (
        <div className={Classes.input_feedback}>{errors}</div>
      )}
    </div>
  )
}

export default Input
