import React,{useState} from "react";
import "./appNav.style.scss";
import "../wallstflowApp.style.scss";
import "../watchlist/watchlist.style.scss"
import { BiSearchAlt } from "react-icons/bi";
import Watchlist from "../watchlist/Watchlist.component";
import {GiHamburgerMenu} from "react-icons/gi";
import Hamburger from "./hamburger.component";

interface props {
  setSearch?: any
  search?: string
 
}

const AppNav:React.FC<props> = ({setSearch, search}) => {
  const [toggleWatchlist, setWatchlist] = useState(false);
  const [toggleHamburger, setHamburger] = useState<boolean>(false)
  
  return (
    <div className="app-main-nav">
      <div className="app-nav-hamburger" onClick={()=>setHamburger(!toggleHamburger)}><GiHamburgerMenu/></div>
      
      <div className={toggleHamburger?"hamburger-active":"hamburger-inactive"}>
          <Hamburger/>
        </div>
      <div className="app-main-nav-logotext">Wall St. Flow</div>
      <div
        className="app-main-nav-watchlist"
        onClick={() => setWatchlist(!toggleWatchlist)}
      >
        Watchlist
      </div>
      <div
            className={
              toggleWatchlist ? "watchlist-popup-true" : "watchlist-popup-false"
            }
          >
            <Watchlist 
              toggleWatchlist={toggleWatchlist}
              setWatchlist={setWatchlist}
            />
          </div>
     
      <div className="app-main-nav-search">
        <input value={search} onChange={(e) => setSearch(e.target.value.toUpperCase())} className="app-main-nav-search-field" type="text" placeholder="Search"/>
        {/* <span className="app-main-nav-search-button">
          <BiSearchAlt />
        </span> */}
      </div>
    
    </div>
  );
};

export default AppNav;
