import React from 'react'

import Classes from './chatDisplay.module.scss'
import { auth } from '../../../firebase/firebase.utils'

const ChatDisplay = ({ mes }: any) => {
  const { message, uid, username }: any = mes

  const messageClass: string =
    uid === auth.currentUser?.uid ? Classes.sent : Classes.received
  return (
    <div className={`${Classes.chat_display} ${messageClass}`}>
      {messageClass === Classes.sent ? <h4>you</h4> : <h4>{username}</h4>}
      <div className={`${Classes.chat_bubble}`} style={{width:"auto"}}>{message}</div>
    </div>
  )
}

export default ChatDisplay
