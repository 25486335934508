import React, { useRef, useEffect } from "react";
import HeadingandSub from "../heading&subtext/Heading&Sub";
import "./MainContent.scss";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap, Power3 } from "gsap";
import Carousel from "./testimonials/Carousel";
import Keyfeatures from "./keyfeatures/Keyfeatures";
import Identify from "../mainContent/identify/Identify";
import Packages from "./packages/Packages";

gsap.registerPlugin(ScrollTrigger);

function MainContent() {

  const testimonialsRef = useRef<HTMLDivElement>(null);
  
  // useEffect(() => {
  //   gsap.to(
  //     testimonialsRef.current,
  //     {
  //       opacity:1,
  //       y: -100,
  //       ease: Power3.easeOut,
  //       scrollTrigger: {
  //         trigger: ".key-features",
  //         start: "20%",
  //         end: "+=100px",
  //         toggleActions: "restart complete none reverse",
  //         onLeaveBack: (self) => self.disable(),
  //         scrub: 1,
  //       },
  //     },
     
  //   );
  // }, []);
  useEffect(() => {
    ScrollTrigger.matchMedia({
	
      // desktop
      "(min-width: 1200px)": function() {gsap.from(
        testimonialsRef.current,
        {
          opacity:0,
          y: 100,
          ease: Power3.easeOut,
          duration: 1,
          scrollTrigger: {
            trigger: ".key-features",
            start: "20%",
            end: "+=100px",
            toggleActions: "restart complete none reverse",
            onLeaveBack: (self) => self.disable(),
            scrub: 1,
          },
        },
      )},
      //
      // phone
      "(max-width: 1199px)": function() {gsap.from(
        testimonialsRef.current,
        {
          opacity:0,
          y: 100,
          ease: Power3.easeOut,
          duration: 1,
          // scrollTrigger: {
          //   trigger: ".key-features",
          //   start: "45%",
          //   end: "+=100px",
          //   toggleActions: "restart complete none reverse",
          //   onLeaveBack: (self) => self.disable(),
          //   scrub: 1,
            
          // },
        },
      )}
      //
    })
  },[]);
  return (
    <div className="MainContent-container">
      <Keyfeatures />
      <Identify />
 
      <div className="testimonials" ref={testimonialsRef}>
        
          <HeadingandSub
            heading="See What The Community Has To Say"
            text="We've reached out to members of our community to see what they think about Wall St Flow."
          />
          <Carousel />
        </div>
      <div className="packages">
      <Packages />
      </div>
    </div>
  );
}

export default MainContent;
