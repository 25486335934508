// import gsap, { Power3 } from "gsap";
import Lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import "./heroAnim.style.scss";

const HeroAnim:React.FC=()=> {
  const [loop, setLoop] = useState(false)
  const heroAnimRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if(heroAnimRef.current) 
    
    Lottie.loadAnimation(
      {
        container: heroAnimRef.current,
        renderer: "svg",
        // loop:1,
        autoplay: true,
        animationData: require("../../../lotties/heroanim.json"),
      },
    );

  }, []);


return <div className="hero-anim-container" ref={heroAnimRef} 
  
  ></div>;
}

export default HeroAnim;
