import React, { useState, useRef, useEffect } from 'react'

import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import firebase, {
  auth,
  createUserProfileDocument,
} from '../../../../firebase/firebase.utils'
import Button from '../../../button/button.component'
import Input from '../../../input/input.component'
import './signin.style.scss'
import ClipLoader from 'react-spinners/ClipLoader'
import { GridLoader } from 'react-spinners'

const SignIn = () => {
  const [recaptcha, setRecaptcha] = useState<any>(null)
  const element = useRef(null)

  useEffect(() => {
    const verifier = new firebase.auth.RecaptchaVerifier(element.current, {
      size: 'invisible',
    })
    if (!recaptcha) {
      verifier.verify().then(() => setRecaptcha(verifier))
    }
  })

  return (
    <div className='signin-phone'>
      <form
        onSubmit={(e) => e.preventDefault()}
        className='phone-form'
        noValidate={true}
      >
        {recaptcha ? (
          <PhoneNumberVerification recaptcha={recaptcha} />
        ) : (
          <GridLoader color={'#D3EAFF'} loading={!recaptcha} size={25} />
        )}
        <div ref={element}></div>
      </form>
    </div>
  )
}

const PhoneNumberVerification = ({ recaptcha }: any) => {
  const [digits, setDigits] = useState({ value: '', cc: '' })
  const [confirmationResult, setConfirmationResult] = useState<any>()
  const [code, setCode] = useState('')
  const [error, setError] = useState<null | boolean | string>(null)
  const [disabled, setDisabled] = useState<boolean | undefined>()
  const [loader, setLoader] = useState(false)
  const phoneNumber = '+' + digits.cc + digits.value

  const signInWithPhoneNumber = async () => {
    try {
      setLoader(true)
      setError(false)
      setConfirmationResult(
        await auth.signInWithPhoneNumber(phoneNumber, recaptcha)
      )
      setLoader(false)
    } catch (err) {
      setLoader(false)
      setError('invalid Phone number')
    }
  }

  const verifyCode = async () => {
    try {
      setLoader(true)
      setError(false)
      const result: any = await confirmationResult.confirm(code)
      createUserProfileDocument(result.user)
      console.log(result)
      setLoader(false)
    } catch (err) {
      setError('Invalid Code')
      setLoader(false)
    }
  }

  const handleChange = async (e: any) => {
    setCode(e.target.value)
    setDisabled(!e.target.value)
  }

  return (
    <>
      <IntlTelInput
        value={digits.value}
        preferredCountries={['us']}
        onPhoneNumberChange={(e: any, value: any, obj: any) =>
          setDigits({ value: value, cc: obj.dialCode })
        }
      />
      {confirmationResult ? (
      <Input
        type='text'
        value={code}
        onChange={handleChange}
        id='code'
        name='code'
        blank='checkout'
      />
    ) : (
      <div className='dummy'>
        Please provide your phone number for us to verify you.
      </div>
    )}
      {confirmationResult ? (
        
        <Button click={verifyCode} types='submit' disabled={disabled}>
          {!loader ? (
            'Verify'
          ) : (
            <GridLoader color={'#D3EAFF'} loading={loader} size={25} />
          )}
        </Button>
        
      ) : (
       
        <Button click={signInWithPhoneNumber} types='submit'>
          {!loader ? (
            'Sign in'
          ) : (
            <GridLoader color={'#D3EAFF'} loading={loader} size={25} />
          )}
        </Button>
       
      )}
      {error && <p className='error'>{error}</p>}
    </>
  )
}

export default SignIn
