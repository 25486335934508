import React, {useEffect} from "react";
import "../../ooftile/ooftile.style.scss";
import "./equityandDarkTile.style.scss";
import UIfx from 'uifx';
import mp3File3 from '../../../../assets/dark.mp3'
interface props{
  time:string
  ticker: string
  quantity: string
  spot: string
  premium: string
}
const dark = new UIfx(mp3File3, {
  throttleMs: 600,
})

const EquityandDarkTile:React.FC<props>=({time, ticker, quantity, spot, premium})=>{
  useEffect(() => {
    dark.play();
  },[]);
  return (
    <div className="ED-tile-container">
      <div className="flex1 ">{time}</div>
      <div className="flex1 ">
        <div className="blue-ticker-border">{ticker}</div>
      </div>
      <div className="flex1 blue-item">{spot}</div>
      <div className="flex1 ">{quantity}</div>
      
      <div className="flex1  green-item">{premium}</div>
    </div>
  );
}

export default EquityandDarkTile;
