import React, { useRef, useEffect } from "react";
import HeadingandSub from "../../heading&subtext/Heading&Sub";
import Card from "../../LandingPageCard/Card";
import "./keyfeatures.style.scss"
import { gsap, Power3,  } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { keyFeaturesArr } from "../../../dataArray";

const Keyfeatures:React.FC=()=> {
  const keyFeaturesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.matchMedia({
	
      //desktop
      "(min-width: 1200px)": function() {gsap.from(
        keyFeaturesRef.current,
        {
          opacity:0,
          y: 100,
          ease: Power3.easeOut,
          duration: 1,
          scrollTrigger: {
            trigger: ".heroSection",
            start: "0%",
            end: "+=100px",
            toggleActions: "restart complete none reverse",
            onLeaveBack: (self) => self.disable(),
            scrub: 1,
          },
        },
      )},
      
      // phone
      "(max-width: 1199px)": function() {gsap.from(
        keyFeaturesRef.current,
        {
          opacity:0,
          y: 100,
          ease: Power3.easeOut,
          duration: 1,
          // scrollTrigger: {
          //   trigger: ".heroSection",
          //   start: "8%",
          //   end: "+=100px",
          //   toggleActions: "restart complete none reverse",
          //   onLeaveBack: (self) => self.disable(),
          //   scrub: 1,
           
          // },
        },
      )}
      //
    })
  },[]);

  return (
    <div className="key-features" ref={keyFeaturesRef}>
      <HeadingandSub
        heading="Key Features"
        text="We provide a plethora of perks to contribute towards your success when trading options."
      />
      <div className="key-features-cards-container">
        <div className="key-features-cards">
          {keyFeaturesArr.map((features) => (
            <Card
              key={features.id}
              icon={features.icon}
              heading={features.heading}
              text={features.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Keyfeatures;
