import React, { useRef, useLayoutEffect, useState } from "react";
import { gsap, Power3 } from "gsap";
import "./HeroSection.scss";
import DisplayLoginRegister from "../../displayLoginRegister/DisplayLoginRegister";
import Button from "../../../button/button.component";
import ScrollDownAnim from "../../animation/ScrollDownAnim";
import HeroAnim from "../../animation/HeroAnim";
import { useNavigate } from "react-router-dom";

const LandingPage:React.FC=()=> {
  const navigate = useNavigate();
  const heroSectionHeadingRef = useRef<HTMLDivElement>(null);
  const heroSectionsubHeadingRef = useRef<HTMLDivElement>(null);
  const heroSectionButtonsRef = useRef<HTMLDivElement>(null);
  const heroImageRef = useRef<HTMLDivElement>(null);
  const [popUpToggle, setPopUp] = useState<string>("");
  const [first, setFirst] = useState<boolean>(true);
  useLayoutEffect(() => {
    gsap.from(heroSectionHeadingRef.current, {
      opacity: 0,
      y: 100,
      ease: Power3.easeOut,
      duration: 1,
    });
    gsap.from(heroSectionsubHeadingRef.current, {
      opacity: 0,
      delay: 0.2,
      y: 100,
      ease: Power3.easeOut,
      duration: 1,
    });
    gsap.from(heroSectionButtonsRef.current, {
      opacity: 0,
      delay: 0.4,
      y: 100,
      ease: Power3.easeOut,
      duration: 1,
    });
    gsap.from(heroImageRef.current, {
      opacity: 0,
      delay: 0.3,
      scale: 0,
      duration: 1,
      ease: Power3.easeOut,
    });
  });

  return (
    <div className="heroSection-Container">
     
      <div className="heroSection">
        <div className="heroSection-text-and-buttons">
          <h1 className="heroSection-text-heading" ref={heroSectionHeadingRef}>
            Institutional-Grade{" "}
            <span style={{ color: "#B7EEFF" }}>Options Market Data</span>{" "}
          </h1>
          <p
            className="heroSection-text-subHeading"
            ref={heroSectionsubHeadingRef}
          >
            {" "}
            Discover what Smart Money is trading with our real-time options
            order flow, news, and alerts.
          </p>
          <div className="heroSection-buttons" ref={heroSectionButtonsRef}>
          <Button
                  click={() =>
                    navigate('checkout?id=price_1IHLAoGDws3elXsYccMjqFRg')
                  }
                  quality='red'
                  types='submit'
                >
                  First month for $29
                </Button> 
              
                <Button
                  click={() =>
                    setPopUp('register')
                  }
                  quality='white'
                  types='submit'
                >
                  Join Us
                </Button> 
              
        
          </div>
        </div>
        <div className="heroSection-heroImage-container">
        
          <HeroAnim />
        </div>
      </div>
      
      <DisplayLoginRegister
        popUpToggle={popUpToggle}
        setPopUp={setPopUp}
        first={first}
        setFirst={setFirst}
      />
      <div className="scroll-down">
        <ScrollDownAnim />
      </div>
    </div>
  );
}

export default LandingPage;
