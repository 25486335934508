import React from "react";
import "./Heading&Sub.scss";
interface props{
heading:string
text:string
}
const HeadingandSub:React.FC<props>=({ heading, text })=>{
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="text-container">
        <h1 className="text-heading">{heading}</h1>
        <p className="text-subheading">{text}</p>
      </div>
    </div>
  );
}

export default HeadingandSub;
