import React, { useEffect, useRef, useState } from 'react'
import { useMedia } from 'use-media'
import './oof.style.scss'
import OOFtile from '../ooftile/OOFtile.component'
import firebase, { firestore } from '../../../firebase/firebase.utils'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { oofTable } from '../../dataArray'
import { GridLoader } from 'react-spinners'
import EquityandDarkCard from '../equity&darkCard/EquityandDarkCard.component'

import { appCardData } from '../../dataArray'
import WallstflowAppCard from '../wallstflowAppcard/WallstflowAppCard.component'

interface props {
  textHeading: string
  value?: any
  search?: string
}
const OOF: React.FC<props> = ({ textHeading, value, search }) => {
  const [mobileLink, setLink] = useState('oof')
  const isMobile = useMedia({ maxWidth: '1200px' })
  const sweepRef = firestore.collection('sweeps')
  let query = null
  query = sweepRef.orderBy('createdAt', 'desc').limit(300) //limit

  if (value) {
    const timeBefore = firebase.firestore.Timestamp.fromDate(
      new Date(value.date)
    )
    const timeAfter = firebase.firestore.Timestamp.fromDate(
      new Date(value.date2)
    )

    query = sweepRef
      .where('createdAt', '>=', timeBefore)
      .where('createdAt', '<=', timeAfter)
      .where('ticker', '==', value.search)
      .limit(100)
  }
  if (search) {
    query = sweepRef
      .where('ticker', '==', search)
      .orderBy('createdAt', 'desc')
      .limit(300)
  }

  const [sweeps, loading] = useCollectionData<any>(query, {
    idField: 'id',
  })

  return (
    <div className='options-order-flow-container'>
      <div className='oof-header'>
        {!isMobile ? (
          <h1 className='oof-textheading'>{textHeading}</h1>
        ) : (
          <ul className='mobile-home-links'>
            <li
              className={mobileLink === 'oof' ? 'linkActive' : 'linkInactive'}
              onClick={() => setLink('oof')}
            >
              OPTIONS ORDER FLOW
            </li>
            <li
              className={mobileLink === 'ed' ? 'linkActive' : 'linkInactive'}
              onClick={() => setLink('ed')}
            >
              EQUITY AND DARK
            </li>
            <li
              className={mobileLink === 'stats' ? 'linkActive' : 'linkInactive'}
              onClick={() => setLink('stats')}
            >
              STATS
            </li>
          </ul>
        )}
        <div className='oof-table'>
          {' '}
          {oofTable.map((el: any, index) => (
            <div className='flex1' key={index}>
              {el}
            </div>
          ))}
        </div>

        <div
          className={`oof-main-tiles ${loading ? 'loading_container' : ''} `}
        >
          {!loading && mobileLink === 'oof' ? (
            sweeps &&
            sweeps.map((data: any) => (
              <div key={data.id} className='oof-tile-background'>
                <OOFtile
                  lastTrade={data.lastTrade}
                  price={data.price}
                  type={data.type}
                  ticker={data.ticker}
                  strike={data.strike}
                  expDate={data.expDate}
                  DTE={data.DTE}
                  details={data.details}
                  openInt={data.openInt}
                  IV={data.IV}
                  premium={data.premium}
                />
              </div>
            ))
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <GridLoader color={'#D3EAFF'} loading={loading} size={25} />
            </div>
          )}

          {mobileLink === 'ed' ? (
            <EquityandDarkCard search={search ? search : ''} />
          ) : mobileLink === 'stats' ? (
            <div className='app-main-cards-container-mobile'>
              {appCardData.map((el, index) => (
                <div
                  className='app-main-cards-container-mobile'
                  key={index}
                  style={{ flex: 1 }}
                >
                  <WallstflowAppCard
                    steps={el.steps}
                    heading={el.heading}
                    subHeading={el.subHeading}
                    progress={el.progress}
                    strokeColor={el.strokeColor}
                  />
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default OOF
