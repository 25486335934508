import React, { useContext, useEffect, useState } from "react";
import "./profile.style.new.scss";
import { IoMdContact } from "react-icons/io";
import Sidebar from "../sidebar/Sidebar.component";
import AppNav from "../appNavbar/appNav.component";
import Button from "../../button/button.component";
import { AuthContext } from "../../../context/context";
import { fetchFromAPI } from "../../../firebase/helper";
import { ClipLoader } from "react-spinners";
import { firestore } from "../../../firebase/firebase.utils";
import { subCardArr } from "../../dataArray";
import SubCard from "../../landingpageComponents/mainContent/packages/subCard/SubCard";
import { date } from "yup";
const Profile: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [subscriptions, setSubscriptions] = useState<any>([{
    plan: {id: '123456'},
    current_period_end: date,

  }]);
  const [isShown, setIsShown] = useState<boolean>(true);
  const [trial, setTrial] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [data, setData] = useState<any>({})

  useEffect(() => {
    getSubscription();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // fetch current subs from api
  const getSubscription = async () => {
    const date = new Date()
    setLoad(true);
    if (user) {
      try {
        const snap = await firestore.collection('users').doc(user.uid).get()
        if (!snap.exists) return
        setData(snap.exists ? snap.data() : null)
        console.timeLog(data)

        // const subs = await fetchFromAPI("subscriptions", { method: "GET" });
        // setSubscriptions(subs);
        setSubscriptions([{
          plan: {id: '123456'},
          current_period_end: date,

        }]);
      } catch (err) {
        console.error(err);
        setSubscriptions([{
          plan: {id: '123456'},
          current_period_end: date,

        }]);
      }
    }
    setLoad(false);
  };

  // cancel a subscriptions
  const cancel = async (id: string) => {
    setLoading(true);
    try {
      await fetchFromAPI("subscriptions/" + id, { method: "PATCH" });
      // bring up a alert msg
      await getSubscription();
      alert("Your Subscription will be cancelled at the end of the period");
    } catch (err: any) {
      alert(err.message);
    }
    setLoading(false);
  };

  return (
    <div className="profile-main-container">
      <div className="profile-sidebar-container">
        <Sidebar />
      </div>
      <div className="profile-main-container-right">
        <AppNav />
        <div className="profile-content">
          <div className="profile-sub-containers">
            <div className="profile-left-container">
              <ClipLoader color={"#000"} loading={load} size={60} />
                      <div className="profile-container">
                        <div className="profile">
                          <h1 className="profile-textHeading">YOUR PROFILE</h1>
                          <div className="profile-icon">
                            <IoMdContact />
                          </div>

                          <div className="profile-userinfo">
                            <div>{`Name - ${data.firstName}`}</div>
                            <span>Email - </span><div style={{ wordWrap:"break-word", fontSize:"0.8rem"}}>{`${data.email}`}</div>
                          </div>
                          <div>
                            <div className="profile-bottom">
                              <div className="current-plan" style={{}}>
                              {`Current Plan - ${subscriptions[0].plan.id}`}
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "2rem",
                                    marginTop:"0.5em"
                                  }}
                                >
                                  {data.product}
                                </div>
                              </div>
                              <div className="upgrade-plan">
                                Change Plan
                                <Button
                                  click={() => {}}
                                  quality="white"
                                  types="submit"
                                >
                                  Change plan
                                </Button>
                              </div>
                              <div className="cancel-plan">
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    color: "#FF6275",
                                  }}
                                  onClick={() => ''}
                                >
                                  Click here
                                </span>{" "}
                                to cancel your membership
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
            
                      </div>
              <div className="profile-right-container">
                <div className="profile-dashboard">
                  <h1 className="dashboard-title">Dashboard</h1>
                  <div className="billing-cycle-container">
                  <div>Billing Cycle - <div className="billing-cycle">{`${new Date(subscriptions[0].current_period_end * 1000).toUTCString()}`}</div></div>
                </div>
                </div>
                {
                  (trial===false)?
                
                <div className="profile-active-plan">
                  <h1 className="activeplan-title">Active Plan</h1>
                  <div className="activeplan-container">
                    <div className="plan-type">STANDARD MONTHLY PLAN</div>

                    <div className="price-circle">$60</div>
                    <span className="duration">/month</span>
                  </div>
                </div>:
                <div className="profile-pricing-card">
                 
                  {subCardArr.map((card) => (
                  <SubCard
                    key={card.planPrice}
                    planType={card.planType}
                    originalPrice={card.originalPrice}
                    planPrice={card.planPrice}
                    duration={card.duration}
                    navigateLink={card.navigateLink}
                    setIsShown={setIsShown}
                  />
                  
                ))}
               
                  </div>
                }
              </div>
            
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Profile;
