import React, {useState} from 'react'
import Button from '../../button/button.component'
import { useNavigate } from 'react-router-dom'
import "./hamburger.style.scss"
import { Link } from 'react-router-dom'
import {AuthContext} from '../../../context/context'
import { auth } from "../../../firebase/firebase.utils";
const Hamburger = () => {
    const [toggleLogout, setLogout] = useState(false);
    const navigate=useNavigate();
    return (
        <div className='hamburger-container'>
            <ul className='hamburger-links'>
                <li className='hamburger-links-home' onClick={()=>{ navigate('/main')}} style={{cursor:"pointer"}} >Home</li>
                <li className='hamburger-links-history' onClick={()=>{ navigate('/history')}} style={{cursor:"pointer"}}>History</li>
                <li className='hamburger-links-charts' onClick={()=>{ navigate('/charts')}} style={{cursor:"pointer"}}>Charts</li>
                <li className='hamburger-links-news' onClick={()=>{ navigate('/news')}} style={{cursor:"pointer"}}>News</li>
                <li className='hamburger-links-profile' onClick={()=>{ navigate('/profile')}} style={{cursor:"pointer"}}>Profile</li>
                <li className='hamburger-links-logout' onClick={()=>{ navigate('/')
                  auth.signOut();}}
                >logout</li>
            </ul>
        </div>
       
        
    )
}

export default Hamburger
