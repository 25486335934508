import React from 'react'
import './aiCapsule.style.scss'
import { AiOutlineTwitter } from 'react-icons/ai'

interface props {
  data: any
  time: any
}
const AiCapsule: React.FC<props> = ({ data, time}) => {
  return (
    <div className='ai-capsule-container'>
      <p className='ai-data'>{data}</p>
      <p className='ai-time'>
        {time}
      </p>
    </div>
  )
}

export default AiCapsule