import React, { useState } from "react";
import {Outlet,  Routes, Route } from "react-router-dom";

import "./wallstflowApp.style.scss";

import Sidebar from "./sidebar/Sidebar.component";

import Watchlist from "./watchlist/Watchlist.component";
import Chat from "../wallstflowapp/chat/Chat.component";
import AppNav from "./appNavbar/appNav.component";
import Home from "./appHome/Home.component";
import Hamburger from "./appNavbar/hamburger.component";


const WallstflowApp:React.FC=()=> {
  const [switcher, setSwitcher] = useState(false)
  const [search, setSearch] = useState<string>('')
  
  return (
    <div className="app-container">
        {/* <Chat click={switcher} sett={setSwitcher} /> */}
      <div className="wallstflow-app">
        <Sidebar />
        <div className="app-main">
        
          <AppNav search={search} setSearch={setSearch}
          />
          <Home search={search}/>
          <Chat click={switcher} sett={setSwitcher} />
        <Outlet/>
        </div>
      </div>
    </div>
  );
}

export default WallstflowApp;
