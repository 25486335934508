import React from "react";
// import { gsap, Power3 } from "gsap";
import "./arrowMenu.style.scss";

import DisplayLoginRegister from "../displayLoginRegister/DisplayLoginRegister";

interface props{
  menuToggle:boolean
  setMenuToggle:any
  setPopUp: any
  popUpToggle: string
  first:boolean
  setFirst:any
}
const ArrowMenu:React.FC<props>=({ menuToggle, setMenuToggle, setPopUp, popUpToggle, first, setFirst })=>{
  // const arrowMenuContainerRef = useRef();

  // useEffect(() => {
  //   gsap.to(
  //     arrowMenuContainerRef.current,
  //     {
  //       x: "600px",
  //       ease: Power3.easeInOut,
  //       transition: "500ms",
  //     },
  //     []
  //   );
  // });
  if (menuToggle === false) return null;
  else if (menuToggle === true)
    return (
      <div className="arrow-menu-container">
        <div
          className="arrow-menu-links login"
          onClick={() => {
            setPopUp("login");
            setMenuToggle(false);
          }}
        >
          login
        </div>
        <div
          className="arrow-menu-links register"
          onClick={() => {
            setPopUp("register");
            setMenuToggle(false);
          }}
        >
          register
        </div>
        <DisplayLoginRegister
        popUpToggle={popUpToggle}
        setPopUp={setPopUp}
        first={first}
        setFirst={setFirst}
      />
      </div>
    );
  else return null;
}

export default ArrowMenu;
