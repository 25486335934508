import WallstflowApp from "./components/wallstflowapp/WallstflowApp.component";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import AuthProvider from "./context/context";
import Charts from "./components/wallstflowapp/charts/Charts.component";
import Profile from "./components/wallstflowapp/appProfile/Profile.component";
import History from "./components/wallstflowapp/History/History.component";
import LandingMain from "./components/landingpageComponents/landingMain/landingMain.component";
import Checkout from "./components/checkout/checkout.component";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import News from "./components/wallstflowapp/app news sectoin/appNews.component";

export const stripePromise = loadStripe(
  'pk_live_51IGxBqGDws3elXsYHZ6hMx0PWrpJlUozAwtMUXPKX8CqpE7djAvMS49iiLNnpJuwUurCA40GFlaMoNW47Xpn7gyR00v0y34SBz'
)
const App:React.FC=()=> {
  return (
    <AuthProvider>

    <div className="App">
      
      {/* <Login logo={logo} />
      <Register logo={logo} /> */}
      <Elements stripe={stripePromise}>
    <Routes>
      <Route path="/" element={<LandingMain />}/>
      
      <Route path="main" element={<WallstflowApp/>}/>
      <Route path="history" element={<History/>}/>
      <Route path="checkout" element={<Checkout/>}/>
      <Route path="charts" element={<Charts/>}/>
      <Route path="profile" element={<Profile/>}/>
      <Route path="news" element={<News/>}/>
      </Routes>
      </Elements>
    </div>
    </AuthProvider>

  );
}

export default App;
