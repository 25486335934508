import React from 'react'
import EquityandDarkTile from './equity&DarkTile/EquityandDarkTile.component'
import './equityandDarkCard.style.scss'
import { useMedia } from 'use-media'
import { firestore } from '../../../firebase/firebase.utils'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { GridLoader } from 'react-spinners'
import EquityandDarkTileMobile from './equity&DarkTile/EDTILEmobile'

interface props {
  search: string
}

const EquityandDarkCard: React.FC<props> = ({ search }) => {
  const darkRef = firestore.collection('dark')
  let querydark = darkRef.orderBy('createdAt', 'desc').limit(200) //limit
  if (search) {
    querydark = darkRef
      .where('ticker', '==', search)
      .orderBy('createdAt', 'desc')
      .limit(200)
  }
  const [darkData, loadingdark] = useCollectionData<any>(querydark, {
    idField: 'id',
  })
  const isMobile = useMedia({ maxWidth: '1200px' })
  return (
    <>
      {!isMobile ? (
        <div className='ED-container'>
          <div className='ED-header'>
            <div className='ED-textheading'>Wall St. Equity & Dark Pool</div>
            <div className='ED-table'>
              <div className='flex1 '>TIME</div>
              <div className='flex1'>TICKER</div>
              <div className='flex1 '>SPOT</div>
              <div className='flex1 '>QUANTITY</div>

              <div className='flex1'>PREMIUM</div>
            </div>
            <div
              className={`ED-tiles ${loadingdark ? 'loading_container' : ''} `}
            >
              {!loadingdark ? (
                darkData &&
                darkData.map((data: any) => (
                  <div key={data.id}>
                    <EquityandDarkTile
                      time={data.time}
                      ticker={data.ticker}
                      quantity={data.quantity}
                      spot={data.ref}
                      premium={data.notional}
                    />
                  </div>
                ))
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <GridLoader
                    color={'#D3EAFF'}
                    loading={loadingdark}
                    size={25}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='ED-mobile-container'>
          <div
            className={`ED-tiles ${loadingdark ? 'loading_container' : ''} `}
          >
            {!loadingdark ? (
              darkData &&
              darkData.map((data: any) => (
                <div key={data.id}>
                  <EquityandDarkTileMobile
                    time={data.time}
                    ticker={data.ticker}
                    quantity={data.quantity}
                    spot={data.ref}
                    premium={data.notional}
                  />
                </div>
              ))
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <GridLoader color={'#D3EAFF'} loading={loadingdark} size={25} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default EquityandDarkCard
