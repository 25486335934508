import React, { useRef, useLayoutEffect } from "react";
import HeadingandSub from "../../heading&subtext/Heading&Sub";
import Card from "../../LandingPageCard/Card";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { identifyArr } from "../../../dataArray";
import "./identify.style.scss"
const Identify:React.FC=()=>{
  const identifyRef = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   gsap.from(
  //     identifyRef.current,
  //     {
  //       opacity:0,
  //       y: 100,
  //       ease: Power3.easeOut,
  //       scrollTrigger: {
  //         trigger: ".heroSection-buttons",
  //         start: "0%",
  //         end: "+=100px",
  //         toggleActions: "restart complete none reverse",
  //         onLeaveBack: (self) => self.disable(),
  //         scrub: 1,
  //       },
  //     },
     
  //   );
  // }, []);
  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
	
      // desktop
      "(min-width: 1200px)": function() {gsap.from(
        identifyRef.current,
        {
          opacity:0,
          y: 100,
          ease: Power3.easeOut,
          duration: 1,
          scrollTrigger: {
            trigger: ".heroSection-buttons",
            start: "0%",
            end: "+=100px",
            toggleActions: "restart complete none reverse",
            onLeaveBack: (self) => self.disable(),
            scrub: 1,
          },
        },
      )},
      //
      // phone
      "(max-width: 1199px)": function() {gsap.from(
        identifyRef.current,
        {
          opacity:0,
          y: 100,
          ease: Power3.easeOut,
          duration: 1,
          // scrollTrigger: {
          //   trigger: ".heroSection-buttons",
          //   start: "-180px",
          //   end: "+=100px",
          //   toggleActions: "restart complete none reverse",
          //   onLeaveBack: (self) => self.disable(),
          //   scrub: 1,
            
          // },
        },
      )}
      //
    })
  },[]);
  return (
    <div className="identify" ref={identifyRef}>
      <HeadingandSub
        heading="Identify Significant Market-Wide Events"
        text="Our proprietary tools aim to bridge the gap between institutional and retail traders."
      />
      <div className="identify-cards-container">
        <div className="identify-cards">
          {identifyArr.map((features) => (
            <Card
              key={features.heading}
              heading={features.heading}
              text={features.text}
              icon={features.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Identify;
