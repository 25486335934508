import React, {useEffect, useRef} from "react";
import "./ooftile.style.scss";
import {gsap, Power3} from 'gsap';
import UIfx from 'uifx';
import mp3File1 from "../../../assets/ai.mp3";

interface props{
  type:string
  ticker:string
  price:string
  premium:string
  lastTrade: string
  strike:string
  expDate: string
  DTE: number
  IV: string
  details: any
  openInt:number
}
const beep = new UIfx(mp3File1, {
  throttleMs: 600,
})
const OOFtile:React.FC<props>=({type, ticker, price, strike, premium, expDate, DTE, IV, details, openInt, lastTrade})=> {
  const gold = premium && premium.slice(-1) === 'M' ? true : false
  const ooftileref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    beep.play();
    gsap.from(ooftileref.current, {
      y: -100,
      ease: Power3.easeInOut,
      duration: 1,
    });
  }, []);
  return (<>
    
    <div className={`oof-tile-container ${gold?"oof-tile-gold":''}`} ref={ooftileref}>
    {gold?<span className="gold-sweep"></span>:''}
      <div className="flex1">{lastTrade}</div>
      <div className="flex1 ">
        <div className={((type==="CALLS")?"green-ticker":((type==="PUTS"||type==="PUT")?"red-ticker":"green-ticker"))}>{ticker}</div>
      </div>
      <div className="flex1">{price}</div>
      <div className={((type==="CALLS")?"green-item":((type==="PUTS"||type==="PUT")?"red-item":"green-item"))+" flex1"}>{type}</div>
      <div className="flex1">{strike}</div>
      <div className="flex1 smaller">{expDate}</div>
      <div className="flex1">{DTE}</div>
      <div className="flex1 smaller">{`${details.hasOwnProperty('last')?`${details.vol} @ ${details.last}` :details} `}</div>
      <div className={((type==="CALLS")?"green-item":((type==="PUTS"||type==="PUT")?"red-item":"green-item"))+" flex1"}>{premium}</div>
      <div className="flex1">{openInt}</div>
      <div className="flex1">{IV}</div>
    
    </div>

    <div className={`oof-tile-container-mobile ${gold?"oof-tile-gold":''}`} ref={ooftileref}>
    {gold?<span className="gold-sweep"></span>:''}
    <div className="oof-tile-container-rearranged gap">
        <div style={{display:"flex" , gap:"2em", margin:"auto 0"}}>
          <div className="gap" style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                <div className="flex1 mobile-table-header" >Ticker</div>
                  <div className="flex1 ">
                    <div className={((type==="CALLS")?"green-ticker":((type==="PUTS"||type==="PUT")?"red-ticker":"green-ticker"))} style={{scale:"1.1"}}>{ticker}</div>
                  </div>
                </div>
                <div className={((type==="CALLS")?"green-item":((type==="PUTS"||type==="PUT")?"red-item":"green-item"))} style={{fontSize:"1rem", display:"flex", alignItems:"flex-end"}}>{premium}</div>
          </div>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <div className="gap" style={{display:"flex", flexDirection:"column", fontWeight:"normal",alignItems:"flex-start"}}>
                  <div className="flex1 mobile-table-header" >Time</div>
                    <div className="flex1" style={{fontSize:"0.5rem"}}>{lastTrade}</div>
                </div>
                <div className="gap" style={{display:"flex", flexDirection:"column",alignItems:"flex-start"}}>
                  <div className="flex1 mobile-table-header" >Price</div>
                    <div className="flex1">{price}</div>
                  </div>
                <div  className="gap" style={{display:"flex", flexDirection:"column",alignItems:"flex-start"}}>
                <div className="flex1 mobile-table-header" >C/P</div>
                  <div className={((type==="CALLS")?"green-item":((type==="PUTS"||type==="PUT")?"red-item":"green-item"))+" flex1"}>{type}</div>
                </div>
                
                <div className="gap" style={{display:"flex", flexDirection:"column",alignItems:"flex-start"}}>
                  <div className="flex1 mobile-table-header" >Strike</div>
                    <div className="flex1">{strike}</div>
                </div>
                <div className="gap" style={{display:"flex", flexDirection:"column",alignItems:"flex-start"}}>
                  <div className="flex1 mobile-table-header" >Expiry</div>
                    <div className="flex1 smaller">{expDate}</div>
                </div>
                
              </div>
              </div>
        </div>
    </>
    
    
  

  );
}

export default OOFtile;
